<main
  class="grid min-h-full place-items-center bg-white dark:bg-gray-900 px-6 py-24 sm:py-32 lg:px-8"
  [@fadeInOut]
>
  <div class="text-center">
    <!-- Dynamic text passed via @Input() -->
    <p
      class="text-xl font-semibold text-primary-600 mb-4"
      [innerHTML]="message"
    ></p>

    <!-- Countdown display -->
    <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">
      Redirecting in {{ countDown }} second<span *ngIf="countDown !== 1">s</span
      >...
    </p>

    <div class="mt-6 flex items-center justify-center gap-x-6">
      <!-- Button to manually go to /dashboard -->
      <button
        (click)="goHome()"
        class="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      >
        Go back home
      </button>
    </div>
  </div>
</main>
