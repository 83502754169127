@if (isLoading$ | async) {
  <div class="flex justify-center items-center w-full h-64">
    <p-progressSpinner class="w-16 h-16"></p-progressSpinner>
  </div>
} @else {
  <div class="relative">
    <button
      (click)="toggleView()"
      class="absolute top-2 right-2 z-10 p-2 sm:px-4 sm:py-2 bg-primary-600 text-white rounded-md shadow-md hover:bg-primary-700 transition-all duration-300 ease-in-out transform hover:scale-105"
    >
      <span class="material-symbols-outlined">
        {{ showFullList ? 'view_carousel' : 'view_list' }}
      </span>
    </button>

    <div class="mt-4 overflow-hidden rounded-2xl bg-white dark:bg-slate-800 border-2 border-gray-200 dark:border-gray-700">
      <div [@slideInOut]="showFullList ? 'out' : 'in'" *ngIf="!showFullList">
        <p-carousel
          [value]="(assessmentCategories$ | async) ?? []"
          [numVisible]="8"
          [numScroll]="1"
          [responsiveOptions]="[
            {
              breakpoint: '1920px',
              numVisible: 8,
              numScroll: 1
            },
            {
              breakpoint: '1440px',
              numVisible: 5,
              numScroll: 1
            },
            {
              breakpoint: '1024px',
              numVisible: 4,
              numScroll: 1
            },
            {
              breakpoint: '768px',
              numVisible: 3,
              numScroll: 1
            },
            {
              breakpoint: '576px',
              numVisible: 2,
              numScroll: 1
            }
          ]"
        >
          <ng-template let-item pTemplate="item">
            @if(!item.assessment.isNotApplicable){
            <div class="p-2 h-full">
              <div
                class="w-full h-full flex flex-col max-w-[150px] mx-auto border-2 rounded-2xl cursor-pointer transition-colors duration-300 ease-in-out hover:bg-gray-100 hover:border-white hover:shadow-default hover:scale-105 dark:hover:bg-slate-700 dark:hover:border-gray-700 dark:hover:shadow-lg"
                [ngClass]="{
                  'scale-110':
                    (currentSelectedCategory$ | async)?.category?._id ===
                    item.category._id
                }"
                [ngStyle]="{
                  'border-color': getScoreColor(item.assessment.score),
                  color: getScoreColor(item.assessment.score),
                  'box-shadow':
                    item &&
                    (currentSelectedCategory$ | async) &&
                    item.category._id ===
                      (currentSelectedCategory$ | async)?.category?._id
                      ? '0 6px 10px -1px ' +
                        getScoreColor(item.assessment.score) +
                        ', 0 2px 4px -2px ' +
                        getScoreColor(item.assessment.score)
                      : 'none'
                }"
                (click)="onCategorySelected(item)"
                (keyup.enter)="onCategorySelected(item)"
                tabindex="0"
              >
                <div>
                  <div class="relative w-full aspect-square flex justify-center items-center p-2">
                    <img
                      [ngSrc]="item.category.avatars[item.category.avatars.length - 1]?.url ?? 'assets/images/empty-avatar.jpg'"
                      [width]="120"
                      [height]="120"
                      [alt]="item.category.name"
                      [priority]="true"
                      class="rounded-xl object-cover w-full h-full"
                    />
                    <p-knob
                      [ngModel]="item.assessment.score.toFixed(0)"
                      [size]="35"
                      class="absolute bg-white dark:bg-slate-900 border-2 border-white rounded-full"
                      readonly="true"
                      [ngStyle]="{ 'left.px': 4, 'top.px': 4 }"
                      valueTemplate="{value}%"
                      [valueColor]="getScoreColor(item.assessment.score)"
                    />
                  </div>
                </div>
                <div class="flex justify-center w-full font-light text-xs px-1">
                  <p class="dark:text-white text-center">
                    {{
                      item.category.name.length > 25
                        ? item.category.name.slice(0, 20) + "..."
                        : item.category.name
                    }}
                  </p>
                </div>
              </div>
            </div>
            }@else {
              <div class="p-2 h-full">
                <div
                  class="w-full h-full flex flex-col max-w-[150px] mx-auto border-2 border-gray-300 dark:border-gray-600 rounded-2xl opacity-60 cursor-pointer"
                  (click)="onCategorySelected(item)"
                  (keyup.enter)="onCategorySelected(item)"
                >
                  <div>
                    <div class="relative w-full aspect-square flex justify-center items-center p-2">
                      <img
                        [ngSrc]="item.category.avatars[item.category.avatars.length - 1]?.url ?? 'assets/images/empty-avatar.jpg'"
                        [width]="120"
                        [height]="120"
                        [alt]="item.category.name"
                        [priority]="true"
                        class="rounded-xl object-cover w-full h-full grayscale"
                      />
                      <div
                        class="absolute top-1 left-1 bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-400 text-[10px] px-2 py-1 rounded-full"
                      >
                        Not Applicable
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center w-full font-light text-xs px-1">
                    <p class="text-gray-500 dark:text-gray-400 text-center">
                      {{
                        item.category.name.length > 25
                          ? item.category.name.slice(0, 20) + "..."
                          : item.category.name
                      }}
                    </p>
                  </div>
                </div>
              </div>
            }
          </ng-template>
        </p-carousel>
      </div>
      <div [@slideInOut]="showFullList ? 'in' : 'out'" *ngIf="showFullList" class="full-list-container">
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 p-4">
          <div *ngFor="let item of (assessmentCategories$ | async) ?? []" class="p-2">
            <div
              class="w-full h-full flex flex-col max-w-[150px] mx-auto border-2 rounded-2xl cursor-pointer transition-colors duration-300 ease-in-out hover:bg-gray-100 hover:border-white hover:shadow-default hover:scale-105 dark:hover:bg-slate-700 dark:hover:border-gray-700 dark:hover:shadow-lg"
              [ngClass]="{
                'scale-110': (currentSelectedCategory$ | async)?.category?._id === item.category._id
              }"
              [ngStyle]="{
                'border-color': getScoreColor(item.assessment.score),
                color: getScoreColor(item.assessment.score),
                'box-shadow':
                  item &&
                  (currentSelectedCategory$ | async) &&
                  item.category._id === (currentSelectedCategory$ | async)?.category?._id
                    ? '0 6px 10px -1px ' + getScoreColor(item.assessment.score) + ', 0 2px 4px -2px ' + getScoreColor(item.assessment.score)
                    : 'none'
              }"
              (click)="onCategorySelected(item)"
              (keyup.enter)="onCategorySelected(item)"
              tabindex="0"
            >
              <div>
                <div class="relative w-full aspect-square flex justify-center items-center p-2">
                  <img
                    [ngSrc]="item.category.avatars[item.category.avatars.length - 1].url"
                    [width]="120"
                    [height]="120"
                    [alt]="item.category.name"
                    [priority]="true"
                    class="rounded-xl object-cover w-full h-full"
                  />
                  <p-knob
                    [ngModel]="item.assessment.score.toFixed(0)"
                    [size]="35"
                    class="absolute bg-white dark:bg-slate-900 border-2 border-white rounded-full"
                    readonly="true"
                    [ngStyle]="{ 'left.px': 4, 'top.px': 4 }"
                    valueTemplate="{value}%"
                    [valueColor]="getScoreColor(item.assessment.score)"
                  />
                </div>
              </div>
              <div class="flex justify-center w-full font-light text-xs px-1">
                <p class="dark:text-white text-center">
                  {{ item.category.name.length > 25 ? item.category.name.slice(0, 20) + "..." : item.category.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
