<cap-sidebar>
  <header class="border-b border-gray-100 dark:border-gray-800">
    <!-- Secondary navigation -->
    <nav class="flex overflow-x-auto py-4">
      <ul
        role="list"
        class="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
      >
        <li>
          <a
            class="hover:text-primary-500"
            routerLink="/settings/account"
            routerLinkActive="text-primary-500 hover:text-primary-400"
            >Account</a
          >
        </li>
      </ul>
    </nav>
  </header>

  <router-outlet />
</cap-sidebar>
