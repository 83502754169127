import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormsModule } from '@angular/forms';
import { SettingsAppActions } from '../../state/settings/settings.actions';
import {
  selectAllSettings,
  selectLogoutTime,
} from '../../state/settings/settings.selectors';
import { PaginationComponent } from 'src/app/shared/pagination/pagination.component';
import { TableComponent } from 'src/app/shared/table/table.component';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';

@Component({
  selector: 'cap-system-page',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './system-page.component.html',
  styleUrl: './system-page.component.scss',
})
export class SystemPageComponent implements OnInit {
  private readonly store = inject(Store);

  systemTimeout = 0;
  passwordLength = 12;
  maxLoginAttempts = 5;
  windowMs = 15;
  blockDuration = 60;

  ngOnInit() {
    this.store.select(selectLogoutTime).subscribe((time) => {
      this.systemTimeout = time;
    });
    this.store.select(selectAllSettings).subscribe((settings) => {
      this.passwordLength = settings.minimumPasswordLength;
      this.systemTimeout = settings.logoutTime;
      this.maxLoginAttempts = settings.maxLoginAttempts;
      this.windowMs = settings.windowMs;
      this.blockDuration = settings.blockDuration;
    });
  }

  updateSettings() {
    this.store.dispatch(
      SettingsAppActions.updateSettings({
        settings: {
          logoutTime: this.systemTimeout,
          minimumPasswordLength: this.passwordLength,
          maxLoginAttempts: this.maxLoginAttempts,
          windowMs: this.windowMs,
          blockDuration: this.blockDuration,
        },
      }),
    );
  }
}
