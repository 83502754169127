import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as d3 from 'd3';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { TreeNode } from 'primeng/api';

interface HierarchyDatum {
  label: string;
  children?: HierarchyDatum[];
}

@Component({
  selector: 'cap-entity-tree-visualization',
  standalone: true,
  imports: [],
  templateUrl: './entity-tree-visualization.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }
      svg {
        overflow: visible;
      }
    `,
  ],
})
export class EntityTreeVisualizationComponent implements OnChanges {
  @Input() data: TreeNode[] | undefined;
  @Input() isSysadmin: boolean = false;
  @Output() nodeSelected = new EventEmitter<TreeNodeSelectEvent>();
  @ViewChild('treeContainer', { static: true })
  private treeContainer!: ElementRef;

  private svg: any;
  private width = 1000;
  private height = 800;
  private margin = { top: 20, right: 90, bottom: 30, left: 90 };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data) {
      this.createTree(this.data);
    }
  }

  private createTree(data: TreeNode[]): void {
    const element = this.treeContainer.nativeElement;
    d3.select(element).selectAll('*').remove(); // Clear previous content

    // Conditionally create a virtual root if isSysadmin is true
    let rootData: TreeNode;
    if (this.isSysadmin) {
      rootData = {
        label: 'CyberWA',
        children: data,
      } as TreeNode;
    } else {
      rootData = {
        children: data,
      } as TreeNode;
    }

    // Create the hierarchy
    const root = d3.hierarchy(rootData, (d) => d.children);

    // Create the tree layout
    const treeLayout = d3.tree<TreeNode>().size([this.height - this.margin.top - this.margin.bottom, this.width - this.margin.left - this.margin.right]);
    treeLayout(root);

    // Create the SVG element
    this.svg = d3.select(element)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    // Create links
    const link = this.svg
      .selectAll('.link')
      .data(root.links())
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('fill', 'none')
      .attr('stroke', '#ccc')
      .attr('stroke-width', 2)
      .attr('d', d3.linkHorizontal()
        .x((d: any) => d.y)
        .y((d: any) => d.x));

    // Create nodes
    const node = this.svg
      .selectAll('.node')
      .data(root.descendants())
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d: any) => `translate(${d.y},${d.x})`)
      .on('click', (event: MouseEvent, d: any) => this.onNodeClick(event, d))
      .attr('cursor', 'pointer');

    // Add circles to nodes
    node.append('circle')
      .attr('r', 10)
      .attr('fill', (d: any) => d.children ? '#69b3a2' : '#3498db')
      .attr('stroke', '#fff')
      .attr('stroke-width', 2);

    // Add labels to nodes
    node.append('text')
      .attr('dy', '.35em')
      .attr('x', (d: any) => d.children ? -13 : 13)
      .attr('text-anchor', (d: any) => d.children ? 'end' : 'start')
      .text((d: any) => d.data.label)
      .attr('fill', 'var(--text-color, #333)')
      .attr('font-family', 'Arial, sans-serif')
      .attr('font-size', '12px');

    // Add hover effect
    node.on('mouseover', function(event:any, d:any) {
      d3.select(event.currentTarget).select('circle').transition()
        .duration(300)
        .attr('r', 15);
    })
    .on('mouseout', (event:any, d:any) => {
      d3.select(event.currentTarget).select('circle').transition()
        .duration(300)
        .attr('r', 10);
    });
  }

  private onNodeClick(event: MouseEvent, d: any): void {
    const treeNode: TreeNode = d.data;
    this.nodeSelected.emit({ node: treeNode, originalEvent: event });
  }
}
