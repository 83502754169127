import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { OrganizationsService } from '../services/organizations.service';
import { catchError, map, of, Subject } from 'rxjs';

export const orgLinkGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const organizationService = inject(OrganizationsService);

  const orgLink = route.paramMap.get('orgLink');
  if (!orgLink) {
    router.navigate(['/not-found']);
    return of(false);
  }

  return organizationService.checkBrandedOrganizationLink(orgLink).pipe(
    map(({ exists }) => {
      if (!exists) {
        router.navigate(['/not-found']);
        return false;
      }

      return true;
    }),
    catchError((error) => {
      console.error('[org-link-guard] error : ', error);
      router.navigate(['/not-found']);
      return of(false);
    }),
  );
};
