<form [formGroup]="profileForm" class="space-y-8">
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
    @if (isUserContext()) {
      <div class="space-y-4">
        <div>
          <label
            for="first-name"
            class="block text-sm font-medium text-gray-700 dark:text-gray-200"
            >First name</label
          >
          <input
            type="text"
            name="first-name"
            id="first-name"
            formControlName="firstName"
            autocomplete="given-name"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            placeholder="Jane"
          />
          <cap-form-error-message
            [control]="profileForm.get('firstName')!"
          ></cap-form-error-message>
        </div>

        <div>
          <label
            for="last-name"
            class="block text-sm font-medium text-gray-700 dark:text-gray-200"
            >Last name</label
          >
          <input
            type="text"
            name="last-name"
            id="last-name"
            formControlName="lastName"
            autocomplete="family-name"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            placeholder="Smith"
          />
          <cap-form-error-message
            [control]="profileForm.get('lastName')!"
          ></cap-form-error-message>
        </div>
      </div>

      <div class="space-y-4">
        @if (isActionUpdate()) {
          <div>
            <label
              for="email"
              class="block text-sm font-medium text-gray-700 dark:text-gray-200"
              >Email address</label
            >
            <p
              id="email"
              class="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 px-3 py-2 text-gray-500 sm:text-sm dark:bg-gray-600 dark:text-gray-300"
            >
              {{ profileForm.get("email")?.value }}
            </p>
          </div>
          @if (isCurrentUserAdmin() | async) {
            <div>
              <label
                for="role"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >Role</label
              >
              <select
                id="role"
                name="role"
                formControlName="role"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="" disabled selected>Select Role</option>
                @for (role of roles$ | async; track role._id) {
                  <option [value]="role._id">{{ role.name | roleName }}</option>
                }
              </select>
            </div>
          } @else {
            <div>
              <label
                for="role"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >Role</label
              >
              <p
                id="role"
                class="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 px-3 py-2 text-gray-500 sm:text-sm dark:bg-gray-600 dark:text-gray-300"
              >
                {{ profileForm.get("role")?.value | roleName }}
              </p>
            </div>
          }
        }
        @if (isActionCreate()) {
          <div>
            <label
              for="email"
              class="block text-sm font-medium text-gray-700 dark:text-gray-200"
              >Email address</label
            >
            <input
              type="email"
              name="email"
              id="email"
              formControlName="email"
              autocomplete="email"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              placeholder="you@example.com"
            />
            <cap-form-error-message
              [control]="profileForm.get('email')!"
            ></cap-form-error-message>
          </div>
          <div>
            <label
              for="role"
              class="block text-sm font-medium text-gray-700 dark:text-gray-200"
              >Role</label
            >
            <select
              id="role"
              name="role"
              formControlName="role"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              <option value="" disabled selected>Select Role</option>
              @for (role of roles$ | async; track role._id) {
                <option [value]="role._id">{{ role.name | roleName }}</option>
              }
            </select>
            <cap-form-error-message
              [control]="profileForm.get('role')!"
            ></cap-form-error-message>
          </div>
        }
      </div>
    } @else if (isOrgContext()) {
      <div class="col-span-full">
        <label
          for="name"
          class="block text-sm font-medium text-gray-700 dark:text-gray-200"
          >Organization Name</label
        >
        <input
          type="text"
          name="name"
          id="name"
          formControlName="name"
          autocomplete="organization"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          placeholder="Acme Inc."
        />
        <cap-form-error-message
          [control]="profileForm.get('name')!"
        ></cap-form-error-message>
      </div>
    }

    <!-- Phone Number Fields -->
    <div class="col-span-full space-y-4" formArrayName="phoneNumbers">
      @for (
        phone of phoneNumbers.controls;
        let phoneIndex = $index;
        track phoneIndex
      ) {
        <div
          [formGroupName]="phoneIndex"
          class="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg shadow-sm"
          [@fadeInOut]
        >
          <div class="flex justify-between items-center mb-2">
            <h4 class="text-lg font-medium text-gray-700 dark:text-gray-200">
              Phone Number {{ phoneIndex + 1 }}
            </h4>
            <div class="space-x-2">
              @if (phoneNumbers.controls.length > 1) {
                <button
                  type="button"
                  (click)="removePhoneNumber(phoneIndex)"
                  class="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                >
                  Remove
                </button>
              }
              <button
                type="button"
                (click)="addPhoneNumberRow()"
                class="text-primary-600 hover:text-primary-800 dark:text-primary-400 dark:hover:text-primary-300"
              >
                Add Phone
              </button>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label
                for="phone-code-{{ phoneIndex }}"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >Country Code</label
              >
              <select
                id="phone-code-{{ phoneIndex }}"
                formControlName="phoneCode"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                @for (
                  country of contriesAndPhoneCodes;
                  track country.countryCode
                ) {
                  <option [value]="country.phoneCode">
                    {{ country.countryName }} (+{{ country.phoneCode }})
                  </option>
                }
              </select>
            </div>
            <div>
              <label
                for="phone-number-{{ phoneIndex }}"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >Phone Number</label
              >
              <input
                type="tel"
                id="phone-number-{{ phoneIndex }}"
                formControlName="number"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                placeholder="5559876543"
              />
              <cap-form-error-message
                [control]="phone.get('number')!"
              ></cap-form-error-message>
            </div>
            <div>
              <label
                for="phone-type-{{ phoneIndex }}"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >Phone Type</label
              >
              <select
                id="phone-type-{{ phoneIndex }}"
                formControlName="phoneType"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="Mobile">Mobile</option>
                <option value="Home">Home</option>
                <option value="Office">Office</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>
          <div class="mt-2">
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                formControlName="isPrimary"
                class="rounded border-gray-300 text-primary-600 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
              />
              <span class="ml-2 text-sm text-gray-700 dark:text-gray-200"
                >Primary</span
              >
            </label>
          </div>
          <cap-form-error-message [control]="phone"></cap-form-error-message>
        </div>
      }
      <cap-form-error-message [control]="phoneNumbers"></cap-form-error-message>
    </div>

    <!-- Address Fields -->
    <div class="col-span-full space-y-4" formArrayName="addresses">
      @for (
        address of addresses.controls;
        let addressIndex = $index;
        track addressIndex
      ) {
        <div
          [formGroupName]="addressIndex"
          class="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg shadow-sm"
          [@fadeInOut]
        >
          <div class="flex justify-between items-center mb-2">
            <h4 class="text-lg font-medium text-gray-700 dark:text-gray-200">
              Address {{ addressIndex + 1 }}
            </h4>
            <div class="space-x-2">
              @if (addresses.controls.length > 1) {
                <button
                  type="button"
                  (click)="removeAddress(addressIndex)"
                  class="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                >
                  Remove
                </button>
              }
              <button
                type="button"
                (click)="addAddressRow()"
                class="text-primary-600 hover:text-primary-800 dark:text-primary-400 dark:hover:text-primary-300"
              >
                Add Address
              </button>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                for="street1-{{ addressIndex }}"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >Street 1</label
              >
              <input
                type="text"
                id="street1-{{ addressIndex }}"
                formControlName="street1"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
              <cap-form-error-message
                [control]="address.get('street1')!"
              ></cap-form-error-message>
            </div>
            <div>
              <label
                for="street2-{{ addressIndex }}"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >Street 2 (Optional)</label
              >
              <input
                type="text"
                id="street2-{{ addressIndex }}"
                formControlName="street2"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div>
              <label
                for="city-{{ addressIndex }}"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >City</label
              >
              <input
                type="text"
                id="city-{{ addressIndex }}"
                formControlName="city"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
              <cap-form-error-message
                [control]="address.get('city')!"
              ></cap-form-error-message>
            </div>
            <div>
              <label
                for="state-{{ addressIndex }}"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >State</label
              >
              <input
                type="text"
                id="state-{{ addressIndex }}"
                formControlName="state"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div>
              <label
                for="country-{{ addressIndex }}"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >Country</label
              >
              <select
                id="country-{{ addressIndex }}"
                formControlName="country"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                @for (
                  country of contriesAndPhoneCodes;
                  track country.countryCode
                ) {
                  <option [value]="country.countryName">
                    {{ country.countryName }}
                  </option>
                }
              </select>
              <cap-form-error-message
                [control]="address.get('country')!"
              ></cap-form-error-message>
            </div>
            <div>
              <label
                for="zip-{{ addressIndex }}"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >Zip</label
              >
              <input
                type="text"
                id="zip-{{ addressIndex }}"
                formControlName="zip"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
              <cap-form-error-message
                [control]="address.get('zip')!"
              ></cap-form-error-message>
            </div>
            <div>
              <label
                for="address-type-{{ addressIndex }}"
                class="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >Address Type</label
              >
              <select
                id="address-type-{{ addressIndex }}"
                formControlName="addressType"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="Office">Office</option>
                <option value="Home">Home</option>
                <option value="Others">Others</option>
              </select>
              <cap-form-error-message
                [control]="address.get('addressType')!"
              ></cap-form-error-message>
            </div>
          </div>
          <div class="mt-2">
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                formControlName="isPrimary"
                class="rounded border-gray-300 text-primary-600 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
              />
              <span class="ml-2 text-sm text-gray-700 dark:text-gray-200"
                >Primary</span
              >
            </label>
          </div>
          <cap-form-error-message [control]="address"></cap-form-error-message>
        </div>
      }
      <cap-form-error-message [control]="addresses"></cap-form-error-message>
    </div>
  </div>
</form>
