import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  fromEventPattern,
  map,
  of,
  share,
  switchMap,
  take,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';

import { AuthAppActions, AuthAPIActions } from './auth.actions';
import { ModalActions } from '../modal/modal.actions';
import { AuthService } from 'src/app/core/services/auth.service';
import { handleError } from 'src/app/utils/error-handler.util';
import { Router } from '@angular/router';
import { AccountDataService } from 'src/app/settings/account-page/account-data.service';
import { selectIsAuthenticated, selectUser } from './auth.state';

export const startTokenExpirationTimer$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        AuthAPIActions.validate2FAOTPSuccess,
        AuthAPIActions.refreshTokenSuccess,
        AuthAPIActions.verify2FAOTPSuccess,
      ),
      switchMap(({ token_expiration }) => {
        const expirationDuration = token_expiration - Date.now();
        return timer(expirationDuration).pipe(
          take(1),
          map(() => AuthAppActions.tokenExpired()),
        );
      }),
    );
  },
  { functional: true },
);

export const handleTokenExpiration$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(AuthAppActions.tokenExpired),
      take(1),
      switchMap(() => of(AuthAppActions.refreshToken({ caller: 'update' }))),
    );
  },
  { functional: true },
);

export const firstLoginDetected$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(AuthAppActions.firstLoginDetected),
      tap(({ user }) => {
        store.dispatch(AuthAppActions.generate2FAOTP({ email: user.email }));
      }),
      map(() => ModalActions.open({ id: 'first-login-setup-modal' })),
    );
  },
  { functional: true },
);

export const showTwoFactorAuthSetupModal$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(AuthAPIActions.loginSuccess),
      withLatestFrom(store.select(selectUser)),
      map(([_, user]) => {
        if (!user!.otpEnabled && !user!.optVerified) {
          store.dispatch(AuthAppActions.generate2FAOTP({ email: user!.email }));
          return ModalActions.open({ id: 'enable-2fa-auth-modal' });
        } else {
          return ModalActions.open({ id: 'validate-otp' });
        }
      }),
    );
  },
  { functional: true },
);

export const emitStorageLoginEvent$ = createEffect(
  (actions$ = inject(Actions), auth = inject(AuthService)) => {
    return actions$.pipe(
      ofType(AuthAPIActions.loginSuccess),
      tap(() => {
        auth.broadcastAuthEvent('login_event');
      }),
    );
  },
  { functional: true, dispatch: false },
);

export const emitStorageLogoutEvent$ = createEffect(
  (actions$ = inject(Actions), auth = inject(AuthService)) => {
    return actions$.pipe(
      ofType(AuthAPIActions.logoutSuccess),
      tap(() => {
        auth.broadcastAuthEvent('logout_event');
      }),
    );
  },
  { functional: true, dispatch: false },
);

export const handleAuthEvents$ = createEffect(
  (auth = inject(AuthService), store = inject(Store)) =>
    fromEventPattern<MessageEvent>(
      (handler) => auth.getAuthChannel().addEventListener('message', handler),
      (handler) =>
        auth.getAuthChannel().removeEventListener('message', handler),
    ).pipe(
      filter((event: MessageEvent) => event.data.tabId !== auth.tabId),
      withLatestFrom(store.select(selectIsAuthenticated)),
      concatMap(([event, isAuthenticated]) => {
        // wait for the event to be processed before handling the next one
        if (event.data.event === 'login_event' && !isAuthenticated) {
          return [AuthAppActions.refreshToken({ caller: 'update' })];
        } else if (event.data.event === 'logout_event' && isAuthenticated) {
          return [AuthAppActions.logout()];
        } else {
          return [];
        }
      }),
    ),
  { functional: true },
);

export const initAuth$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(AuthAppActions.initAuth),
      map(() => AuthAppActions.refreshToken({ caller: 'initialize' })),
    );
  },
  { functional: true },
);

export const login$ = createEffect(
  (actions$ = inject(Actions), auth = inject(AuthService)) => {
    return actions$.pipe(
      ofType(AuthAppActions.login),
      exhaustMap(({ email, password }) =>
        auth.login({ email, password }).pipe(
          take(1),
          map(({ user }) => {
            if (!user.lastLogin) {
                return AuthAppActions.firstLoginDetected({ user });
            } else {
              return AuthAPIActions.loginSuccess({ user });
            }
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.loginFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);
export const logout$ = createEffect(
  (
    actions$ = inject(Actions),
    auth = inject(AuthService),
    router = inject(Router),
  ) => {
    return actions$.pipe(
      ofType(AuthAppActions.logout),
      switchMap(() =>
        auth.logout().pipe(
          take(1),
          map(() => {
            router.navigate(['/auth/login']);
            return AuthAPIActions.logoutSuccess();
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.logoutFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const refreshToken$ = createEffect(
  (
    actions$ = inject(Actions),
    auth = inject(AuthService),
    router = inject(Router),
    store = inject(Store),
  ) => {
    return actions$.pipe(
      ofType(AuthAppActions.refreshToken),
      switchMap(({ caller }) =>
        auth.refreshToken().pipe(
          take(1),
          map((response) => {
            return AuthAPIActions.refreshTokenSuccess(response);
          }),
          catchError((error) => {
            return of(AuthAPIActions.refreshTokenFailure());
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const changePassword$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    account = inject(AccountDataService),
  ) => {
    return actions$.pipe(
      ofType(AuthAppActions.changePassword),
      exhaustMap(({ currentPassword, newPassword, confirmNewPassword }) =>
        account
          .changePassword({
            currentPassword,
            newPassword,
            confirmNewPassword,
          })
          .pipe(
            take(1),
            map((response) => {
              return AuthAPIActions.changePasswordSuccess(response);
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(AuthAPIActions.changePasswordFailure({ message }));
            }),
          ),
      ),
    );
  },
  {
    functional: true,
  },
);

// TODO: Handle Error Alert in the 'set-password-modal' component
export const setNewPassword$ = createEffect(
  (actions$ = inject(Actions), auth = inject(AuthService)) => {
    return actions$.pipe(
      ofType(AuthAppActions.setNewPassword),
      exhaustMap(({ email, newPassword, confirmPassword, isEula }) =>
        auth
          .setNewPassword({ email, newPassword, confirmPassword, isEula })
          .pipe(
            take(1),
            map(({ user }) => {
              return AuthAPIActions.setNewPasswordSuccess({ user });
            }),
            catchError((error) => {
              const message = handleError(error);
              return of(AuthAPIActions.setNewPasswordFailure({ message }));
            }),
          ),
      ),
    );
  },
  { functional: true },
);

// TODO: Handle Error Alert in the 'forgot-password' component
export const forgotPassword$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    auth = inject(AuthService),
  ) => {
    return actions$.pipe(
      ofType(AuthAppActions.forgotPassword),
      exhaustMap(({ email }) =>
        auth.forgotPassword(email).pipe(
          take(1),
          map((response) => {
            return AuthAPIActions.forgotPasswordSuccess();
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.forgotPasswordFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

// TODO: Handle Error Alert in the 'reset-password' component
export const resetPassword$ = createEffect(
  (
    actions$ = inject(Actions),
    auth = inject(AuthService),
    router = inject(Router),
  ) => {
    return actions$.pipe(
      ofType(AuthAppActions.resetPassword),
      exhaustMap(({ token, newPassword, confirmPassword }) =>
        auth.resetPassword({ token, newPassword, confirmPassword }).pipe(
          take(1),
          map((response) => {
            router.navigate(['/auth/login']);
            return AuthAPIActions.resetPasswordSuccess();
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.resetPasswordFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const updateUserAccount$ = createEffect(
  (actions$ = inject(Actions), account = inject(AccountDataService)) => {
    return actions$.pipe(
      ofType(AuthAppActions.updateUserAccount),
      exhaustMap(({ user }) =>
        account.updateAccount(user).pipe(
          take(1),
          map((response) => {
            return AuthAPIActions.updateUserAccountSuccess(response);
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.updateUserAccountFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const resendOtp$ = createEffect(
  (actions$ = inject(Actions), auth = inject(AuthService)) => {
    return actions$.pipe(
      ofType(AuthAppActions.resendEmailOTP),
      exhaustMap((props) =>
        auth.resendEmailOTP(props.email).pipe(
          take(1),
          map((response) => {
            return AuthAPIActions.resendEmailOTPSuccess();
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.resendEmailOTPFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const generate2FAOTP$ = createEffect(
  (actions$ = inject(Actions), auth = inject(AuthService)) => {
    return actions$.pipe(
      ofType(AuthAppActions.generate2FAOTP),
      exhaustMap((props) =>
        auth.generate2FAOTP(props.email).pipe(
          take(1),
          map((response) => {
            return AuthAPIActions.generate2FAOTPSuccess(response);
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.generate2FAOTPFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const verify2FAOTP$ = createEffect(
  (
    actions$ = inject(Actions),
    auth = inject(AuthService),
    router = inject(Router),
  ) => {
    return actions$.pipe(
      ofType(AuthAppActions.verify2FAOTP),
      exhaustMap(({ email, token }) =>
        auth.verify2FAOTP({ email, token }).pipe(
          take(1),
          map((response) => {
            // router.navigate(['/']);
            return AuthAPIActions.verify2FAOTPSuccess(response);
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.verify2FAOTPFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const validate2FAOTP$ = createEffect(
  (
    actions$ = inject(Actions),
    auth = inject(AuthService),
    router = inject(Router),
  ) => {
    return actions$.pipe(
      ofType(AuthAppActions.validate2FAOTP),
      exhaustMap(({ email, token }) =>
        auth.validate2FAOTP({ email, token }).pipe(
          take(1),
          map((response) => {
            router.navigate(['/']);
            return AuthAPIActions.validate2FAOTPSuccess(response);
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.validate2FAOTPFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const reset2FAOTP$ = createEffect(
  (actions$ = inject(Actions), auth = inject(AuthService)) => {
    return actions$.pipe(
      ofType(AuthAppActions.reset2FAOTP),
      exhaustMap((props) =>
        auth.reset2FAOTP(props.email).pipe(
          take(1),
          map((response) => AuthAPIActions.reset2FAOTPSuccess(response)),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.reset2FAOTPFailure({ message }));
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const verifyResetting2FAOTP$ = createEffect(
  (actions$ = inject(Actions), auth = inject(AuthService)) => {
    return actions$.pipe(
      ofType(AuthAppActions.verifyResetting2FAOTP),
      exhaustMap((props) =>
        auth.verifyResetting2FAOTP(props.token).pipe(
          take(1),
          map((response) =>
            AuthAPIActions.verifyResetting2FAOTPSuccess(response),
          ),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.verifyResetting2FAOTPFailure({ message }));
          }),
          share(),
        ),
      ),
    );
  },
  { functional: true },
);

export const updateUserAvatar$ = createEffect(
  (
    actions$ = inject(Actions),
    account = inject(AccountDataService),
    store = inject(Store),
  ) => {
    return actions$.pipe(
      ofType(AuthAppActions.updateUserAvatar),
      exhaustMap(({ avatar }) =>
        account.updateUserAvatars(avatar).pipe(
          take(1),
          tap(() => store.dispatch(ModalActions.close({ id: 'avatar' }))),
          map(({ avatars }) => {
            return AuthAPIActions.updateUserAvatarSuccess({ avatars });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.updateUserAvatarFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);

export const removeUserAvatar$ = createEffect(
  (actions$ = inject(Actions), account = inject(AccountDataService)) => {
    return actions$.pipe(
      ofType(AuthAppActions.removeUserAvatar),
      exhaustMap(({ avatar }) =>
        account.removeUserAvatar(avatar).pipe(
          take(1),
          map(({ avatar }) => {
            return AuthAPIActions.removeUserAvatarSuccess({ avatar });
          }),
          catchError((error) => {
            const message = handleError(error);
            return of(AuthAPIActions.removeUserAvatarFailure({ message }));
          }),
        ),
      ),
    );
  },
  { functional: true },
);