import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IFeedback } from '../models/feedback.model';
import { Pagination } from 'src/app/state/categories/categories.state';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private http = inject(HttpClient)
  private feedbackPath = 'feedback'

  constructor() { }


  createFeedback({ title, description }: { title: string, description: string }) {
    return this.http.post<{ feedback: IFeedback }>(`${environment.apiUrl}/${this.feedbackPath}/`, { title, description });
  }

  getFeedbacks(page: number, pageSize: number) {
    return this.http.get<{ feedbacks: IFeedback[], pagination: Pagination }>(`${environment.apiUrl}/${this.feedbackPath}?page=${page}&limit=${pageSize}`);
  }

  resolveFeedback(id: string) {
    return this.http.put<{ feedback: IFeedback }>(`${environment.apiUrl}/${this.feedbackPath}/resolve/${id}`, {});
  }

  deleteFeedbacks(ids: string[]) {
    return this.http.delete<{ message: string }>(`${environment.apiUrl}/${this.feedbackPath}`, { body: { ids } });
  }
}
