import { Component, OnInit, inject, signal } from '@angular/core';
import { OrganizationsService } from '../core/services/organizations.service';
import { ActivatedRoute } from '@angular/router';
import { RouterModule } from '@angular/router';

import { IAvatar } from '../core/models/user.model';
@Component({
  selector: 'cap-branded-page',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './branded-page.component.html',
  styleUrl: './branded-page.component.scss'
})
export class BrandedPageComponent implements OnInit{
  private organizationsService = inject(OrganizationsService);
  private route = inject(ActivatedRoute);
  currentOrg = signal<{name: string, avatars: IAvatar[], brandPageContent: string} | null>(null);
  orgLink: string = ''

  ngOnInit(): void {
    this.orgLink = this.route.snapshot.params['orgLink'];
    this.organizationsService.getBrandedOrganizationInfo(this.orgLink).subscribe((res) => {
      this.currentOrg.set(res.org)
    });
  }

  getOrgLogo(){
    if(!this.currentOrg()?.avatars?.length) return null;
    return this.currentOrg()?.avatars[(this.currentOrg()?.avatars.length ?? 0) - 1]?.url
  }
}
