import { createFeature, createReducer, on } from '@ngrx/store';

import { AuthAPIActions, AuthAppActions } from './auth.actions';
import { IUser } from 'src/app/core/models/user.model';

interface State {
  user: IUser | null;
  accessToken: string | null;
  isAuthenticated: boolean | null;
  errorMessage: string | null;
}

const initialState: State = {
  user: null,
  accessToken: null,
  isAuthenticated: null,
  errorMessage: null,
};

export const authFeature = createFeature({
  name: 'auth',
  reducer: createReducer(
    initialState,

    on(AuthAppActions.login, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(AuthAPIActions.loginSuccess, (state, { user }) => ({
      ...state,
      user,
      isAuthenticated: false,
      errorMessage: null,
    })),
    on(AuthAPIActions.loginFailure, (state, { message }) => ({
      ...state,
      user: null,
      accessToken: null,
      isAuthenticated: false,
      errorMessage: message,
    })),
    on(AuthAppActions.firstLoginDetected, (state, { user }) => ({
      ...state,
      user,
      isAuthenticated: false,
      errorMessage: null,
    })),
    // on(AuthAppActions.refreshToken, (state) => ({
    //   ...state,
    // })),
    on(AuthAPIActions.refreshTokenSuccess, (state, { access_token, user }) => ({
      ...state,
      user,
      accessToken: access_token,
      isAuthenticated: true,
    })),
    on(AuthAPIActions.refreshTokenFailure, (state) => ({
      ...state,
      isAuthenticated: false,
      accessToken: null,
      user: null,
    })),
    // on(AuthAppActions.logout, (state) => ({
    //   ...state,
    //   errorMessage: null,
    // })),
    on(AuthAPIActions.logoutSuccess, (state) => ({
      ...state,
      user: null,
      accessToken: null,
      isAuthenticated: false,
      errorMessage: null,
    })),
    on(AuthAPIActions.logoutFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(AuthAppActions.changePassword, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(AuthAPIActions.changePasswordSuccess, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(AuthAPIActions.changePasswordFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(AuthAppActions.forgotPassword, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(AuthAPIActions.forgotPasswordSuccess, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(AuthAPIActions.forgotPasswordFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(AuthAppActions.resetPassword, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(AuthAPIActions.resetPasswordSuccess, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(AuthAPIActions.resetPasswordFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(AuthAppActions.setNewPassword, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(AuthAPIActions.setNewPasswordSuccess, (state, { user }) => ({
      ...state,
      user,
      isAuthenticated: false,
      errorMessage: null,
    })),
    on(AuthAPIActions.setNewPasswordFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(AuthAppActions.resendEmailOTP, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(AuthAPIActions.resendEmailOTPSuccess, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(AuthAPIActions.resendEmailOTPFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(AuthAPIActions.updateUserAvatarSuccess, (state, { avatars }) => ({
      ...state,
      user: {
        ...state.user!,
        avatars,
      },
    })),
    on(AuthAPIActions.updateUserAvatarFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(AuthAPIActions.removeUserAvatarSuccess, (state, { avatar }) => {
      if (!state.user) {
        return state;
      }

      const updatedAvatars = state.user.avatars.filter(
        (av) => av.data !== avatar.data,
      );

      // Optionally, handle the case where the removed avatar was the current avatar
      // and set a new avatar as current, if necessary.

      return {
        ...state,
        user: {
          ...state.user,
          avatars: updatedAvatars,
        },
      };
    }),
    on(AuthAPIActions.removeUserAvatarFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(AuthAppActions.updateUserAccount, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(AuthAPIActions.updateUserAccountSuccess, (state, user) => ({
      ...state,
      errorMessage: null,
      ...user,
    })),
    on(AuthAPIActions.updateUserAccountFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(
      AuthAPIActions.generate2FAOTPSuccess,
      (state, { otpAuthUrl, otpBase32 }) => ({
        ...state,
        user: {
          ...state.user!,
          otpAuthUrl,
          otpBase32,
        },
      }),
    ),
    on(AuthAPIActions.generate2FAOTPFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(AuthAPIActions.verify2FAOTPSuccess, (state, { user, access_token }) => ({
      ...state,
      user,
      accessToken: access_token,
      isAuthenticated: true,
      errorMessage: null,
    })),
    on(AuthAPIActions.verify2FAOTPFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(
      AuthAPIActions.validate2FAOTPSuccess,
      (state, { user, access_token }) => ({
        ...state,
        user,
        accessToken: access_token,
        isAuthenticated: true,
        errorMessage: null,
      }),
    ),
    on(AuthAPIActions.validate2FAOTPFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(AuthAPIActions.reset2FAOTPFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
    on(
      AuthAPIActions.verifyResetting2FAOTPSuccess,
      (state, { message, email, otpAuthUrl, otpBase32 }) => ({
        ...state,
        user: {
          ...state.user!,
          email,
          otpAuthUrl,
          otpBase32,
        },
      }),
    ),
    on(AuthAPIActions.verifyResetting2FAOTPFailure, (state, { message }) => ({
      ...state,
      errorMessage: message,
    })),
  ),
});

export const {
  name,
  reducer,
  selectAuthState,
  selectAccessToken,
  selectIsAuthenticated,
  selectUser,
  selectErrorMessage,
} = authFeature;
