import { Component, Input } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'cap-form-error-message',
  standalone: true,
  imports: [CommonModule, NgIf, NgFor],
  template: `
    @if(showError) {
      @for(error of errorMessages; track error) {
        <p class="text-red-600 mt-2 text-xs">{{ error }}</p>
      }
    }
  `,
  styleUrl: './form-error-message.component.scss',
})
export class FormErrorMessageComponent {
  @Input() control!: AbstractControl;

  get showError(): boolean {
    return (
      this.control &&
      this.control.invalid &&
      (this.control.dirty || this.control.touched) &&
      !this.control.pending
    );
  }

  get errorMessages(): string[] {
    if (!this.control.errors) {
      return [];
    }

    return Object.entries(this.control.errors).map(([errorKey, errorValue]) => {
      switch (errorKey) {
        case 'required':
          return 'This field is required';
        case 'minlength':
          return `Minimum length required is ${errorValue.requiredLength}`;
        case 'email':
          return 'Invalid email format';
        case 'noPrimary':
          return 'One entry must be marked as primary';
        case 'invalidPhoneNumber':
          return 'Invalid phone number format';
        case 'notNumeric':
          return 'Zip code must be numeric';
        case 'min':
          return 'Invalid field value';
        case 'max':
          return 'Invalid field value';
        case 'emailExists':
          return 'Email already exists';
        default:
          return `Error: ${errorKey}`;
      }
    });
  }
}
