import { Component, EventEmitter, inject, OnInit, Output, signal, ViewChild } from '@angular/core';
import { Stepper, StepperModule } from 'primeng/stepper';
import { FormBuilder, FormArray, ReactiveFormsModule, Validators, FormGroup } from '@angular/forms';
import { FormErrorMessageComponent } from "../shared/form-error-message/form-error-message.component";
import { IAddress, IAvatar, IPhoneNumber } from '../core/models/user.model';
import { isNumericValidator } from '../shared/validators/is-numeric.validator';
import { CommonModule } from '@angular/common';
import {
  CountryPhoneCodeService,
  ICountryPhoneCode,
} from 'src/app/core/services/country-phone-code.service';
import { ButtonModule } from 'primeng/button';
import { PaymentFormComponent } from "../shared/payment-form/payment-form.component";
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { OrganizationsService } from '../core/services/organizations.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { OrganizationsAppActions } from '../state/organizations/organizations.actions';
import { IsEmailUniqueValidatorDirective } from '../shared/validators/is-email-unique.validator';
import { UsersService } from '../core/services/users.service';

interface DropdownState {
  [key: string]: boolean;
}

@Component({  
  selector: 'cap-branded-signup-page',
  standalone: true,
  imports: [StepperModule, RouterModule, ReactiveFormsModule,FormErrorMessageComponent, ToastModule,CommonModule, ButtonModule, PaymentFormComponent],
  templateUrl: './branded-signup-page.component.html',
  styleUrl: './branded-signup-page.component.scss',
  providers: [MessageService]
})
export class BrandedSignupPageComponent implements OnInit {
  @ViewChild('stepper') stepper!: Stepper;
  private router = inject(Router);
  private store = inject(Store);
  private fb = inject(FormBuilder);
  private countryPhoneCodeService = inject(CountryPhoneCodeService);
  private orgService = inject(OrganizationsService);
  private messageService = inject(MessageService);
  private userService = inject(UsersService);
  @Output() nextCallback = new EventEmitter<void>();
  isPhoneCodeDropdownOpen: DropdownState = {};
  isCountryDropdownOpen: DropdownState = {};
  brandedOrgForm: FormGroup = this.fb.group({});
  contriesAndPhoneCodes: ICountryPhoneCode[] = [];
  currentOrg = signal<{name: string, avatars: IAvatar[]} | null>(null);
  orgLink = inject(ActivatedRoute).snapshot.params['orgLink'];
  get adminPhoneNumbers() {
    return this.brandedOrgForm.get('adminDetails.adminPhoneNumbers') as FormArray;
  }

  get orgPhoneNumbers() {
    return this.brandedOrgForm.get('orgDetails.orgPhoneNumbers') as FormArray;
  }

  get adminAddresses() {
    return this.brandedOrgForm.get('adminDetails.adminAddresses') as FormArray;
  }

  get orgAddresses() {
    return this.brandedOrgForm.get('orgDetails.orgAddresses') as FormArray;
  }

  defaultCountry = this.countryPhoneCodeService.getCountryPhoneCodes()?.[0]

  ngOnInit() {
    this.orgService.getBrandedOrganizationInfo(this.orgLink).subscribe((org) => {
      this.currentOrg.set({name: org.org.name, avatars: org.org.avatars});
    });
    this.brandedOrgForm = this.fb.group({
      adminDetails: this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', {
          validators: [Validators.required, Validators.email],
          asyncValidators: [IsEmailUniqueValidatorDirective.createValidator(this.userService)],
          updateOn: 'blur'
        }],
        adminAddresses: this.fb.array([this.createAddressFormGroup('adminDetails')]),
        adminPhoneNumbers: this.fb.array([this.createPhoneNumberFormGroup('adminDetails')])
      }),
      orgDetails: this.fb.group({
        name: ['', Validators.required],
        orgPhoneNumbers: this.fb.array([this.createPhoneNumberFormGroup('orgDetails')]),
        orgAddresses: this.fb.array([this.createAddressFormGroup('orgDetails')])
      }),
      paymentIntentId: ['', Validators.required],
    });
    this.contriesAndPhoneCodes = this.countryPhoneCodeService.getCountryPhoneCodes();

    const adminPhoneNumbers = this.brandedOrgForm.get('adminDetails.adminPhoneNumbers') as FormArray;
    const adminAddresses = this.brandedOrgForm.get('adminDetails.adminAddresses') as FormArray;
    const orgPhoneNumbers = this.brandedOrgForm.get('orgDetails.orgPhoneNumbers') as FormArray;
    const orgAddresses = this.brandedOrgForm.get('orgDetails.orgAddresses') as FormArray;

    if (adminPhoneNumbers.length > 0) adminPhoneNumbers.at(0).get('isPrimary')?.setValue(true);
    if (adminAddresses.length > 0) adminAddresses.at(0).get('isPrimary')?.setValue(true);
    if (orgPhoneNumbers.length > 0) orgPhoneNumbers.at(0).get('isPrimary')?.setValue(true);
    if (orgAddresses.length > 0) orgAddresses.at(0).get('isPrimary')?.setValue(true);

    // Debug subscription
    const emailControl = this.brandedOrgForm.get('adminDetails.email');
    emailControl?.statusChanges.subscribe(status => {
        if (emailControl.errors) {
            console.log('Email Exists Error:', emailControl.errors['emailExists']);
        }
    });
  }

  onNextStepper(index:number): void{
    if(index === 0){
      if(this.brandedOrgForm.controls['orgDetails'].valid){
        this.stepper.activeStep = 1;
      }else{
        this.stepper.activeStep = 0;
        console.log('error');
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Please fill all the required fields'});
      }
    }else if(index === 1){
      if(this.brandedOrgForm.controls['adminDetails'].valid){
        this.stepper.activeStep = 2;
      }else{
        this.stepper.activeStep = 1;
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Please fill all the required fields'});
      }
    } else if(index === 3){
      if(this.brandedOrgForm.controls['paymentIntentId'].valid){
      }else{
        this.stepper.activeStep = 3;
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Payment is required to create an organization'});
      }
    }
  }

  onPrevStepper(index:number): void{
    if(index === 1){
      this.stepper.activeStep = 0;
    }else if(index === 2){
      this.stepper.activeStep = 1;
    }
  }

  private createAddressFormGroup(section: 'adminDetails' | 'orgDetails'): FormGroup {
    return this.fb.group({
      street1: ['', Validators.required],
      street2: [''],
      city: ['', Validators.required],
      state: [''],
      country: [this.defaultCountry.countryName , Validators.required],
      zip: ['', Validators.required],
      addressType: ['Office'],
      isPrimary: [false]
    });
  }

  private createPhoneNumberFormGroup(section: 'adminDetails' | 'orgDetails'): FormGroup {
    return this.fb.group({
      phoneCode: [this.defaultCountry?.phoneCode, Validators.required],
      number: ['', Validators.required],
      phoneType: ['Mobile'],
      isPrimary: [false]
    });
  }

  removePhoneNumber(index: number, section: 'adminDetails' | 'orgDetails'): void {
    const formArray = section === 'adminDetails' ? 
      this.brandedOrgForm.get('adminDetails.adminPhoneNumbers') as FormArray :
      this.brandedOrgForm.get('orgDetails.orgPhoneNumbers') as FormArray;
    formArray.removeAt(index);
    formArray.markAsDirty();
  }

  addPhoneNumberRow(section: 'adminDetails' | 'orgDetails'): void {
    const formArray = section === 'adminDetails' ? 
      this.brandedOrgForm.get('adminDetails.adminPhoneNumbers') as FormArray :
      this.brandedOrgForm.get('orgDetails.orgPhoneNumbers') as FormArray;
    formArray.push(this.createPhoneNumberGroup(null, formArray.length === 0, section));
    this.isPhoneCodeDropdownOpen[`${section}-${formArray.length - 1}`] = false;
  }

  private createPhoneNumberGroup(
    phone: IPhoneNumber | null,
    isFirstRow: boolean = false,
    section: 'adminDetails' | 'orgDetails'
  ): FormGroup {
    const phoneGroup = this.fb.group({
      phoneCode: [phone?.phoneCode ?? '1', Validators.required],
      number: [phone?.number ?? '', [Validators.required]],
      phoneType: [phone?.phoneType ?? 'Mobile', Validators.required],
      isPrimary: [
        isFirstRow || (phone?.isPrimary ?? false),
        Validators.required,
      ],
    });

    this.setupPrimarySubscription(phoneGroup, 'phoneNumbers', section);
    return phoneGroup;
  }

  private setupPrimarySubscription(group: FormGroup, formArrayName: string, section: 'adminDetails' | 'orgDetails'): void {
    group.get('isPrimary')?.valueChanges.subscribe((value) => {
      if (value) {
        const formArray = this.brandedOrgForm.get(`${section}.${formArrayName}`) as FormArray;
        formArray.controls.forEach((control) => {
          if (control !== group) {
            control.get('isPrimary')?.setValue(false);
          }
        });
      }
    });
  }

  togglePhoneCodeDropdown(index: number, section: 'adminDetails' | 'orgDetails'): void {
    const key = `${section}-${index}`;
    this.isPhoneCodeDropdownOpen[key] = !this.isPhoneCodeDropdownOpen[key];
  }

  selectPhoneCode(index: number, phoneCode: string, section: 'adminDetails' | 'orgDetails'): void {
    if(section === 'adminDetails'){
      const formArray = this.brandedOrgForm.get('adminDetails.adminPhoneNumbers') as FormArray;
      formArray.at(index).get('phoneCode')?.setValue(phoneCode);
    }else{
      const formArray = this.brandedOrgForm.get('orgDetails.orgPhoneNumbers') as FormArray;
      formArray.at(index).get('phoneCode')?.setValue(phoneCode);
    }
    this.isPhoneCodeDropdownOpen[`${section}-${index}`] = false;
  }

  removeAddress(index: number, section: 'adminDetails' | 'orgDetails'): void {
    const formArray = section === 'adminDetails' ? 
      this.brandedOrgForm.get('adminDetails.adminAddresses') as FormArray :
      this.brandedOrgForm.get('orgDetails.orgAddresses') as FormArray;
    formArray.removeAt(index);
    formArray.markAsDirty();
  }

  private createAddressGroup(
    address: IAddress | null,
    isFirstRow: boolean = false,
    section: 'adminDetails' | 'orgDetails'
  ): FormGroup {
    const addressGroup = this.fb.group({
      street1: [address?.street1 ?? '', Validators.required],
      street2: [address?.street2 ?? ''],
      city: [address?.city ?? '', Validators.required],
      state: [address?.state ?? ''],
      country: [
        address?.country ?? 'United States of America',
        Validators.required,
      ],
      zip: [address?.zip ?? '', [Validators.required, isNumericValidator()]],
      addressType: [address?.addressType ?? 'Office', Validators.required],
      isPrimary: [
        isFirstRow || (address?.isPrimary ?? false),
        Validators.required,
      ],
    });
    this.setupPrimarySubscription(addressGroup, 'addresses', section);
    return addressGroup;
  }
  

  addAddressRow(section: 'adminDetails' | 'orgDetails'): void {
    const formArray = section === 'adminDetails' ? 
      this.brandedOrgForm.get('adminDetails.adminAddresses') as FormArray :
      this.brandedOrgForm.get('orgDetails.orgAddresses') as FormArray;
    formArray.push(this.createAddressGroup(null, formArray.length === 0, section));
    this.isCountryDropdownOpen[`${section}-${formArray.length - 1}`] = false;
  }

  getCountryCodeByName(countryName: string): string {
    return (
      this.countryPhoneCodeService.getCountryPhoneCodes()
        ?.find((obj) => obj.countryName === countryName)
        ?.countryCode.toLocaleLowerCase() ?? ''
    );
  }

  toggleCountryDropdown(index: number, section: 'adminDetails' | 'orgDetails'): void {
    const key = `${section}-${index}`;
    this.isCountryDropdownOpen[key] = !this.isCountryDropdownOpen[key];
  }

  selectCountry(index: number, countryName: string, section: 'adminDetails' | 'orgDetails'): void {
    const formArray = this.brandedOrgForm.get(`${section}.addresses`) as FormArray;
    formArray.at(index).get('country')?.setValue(countryName);
    this.isCountryDropdownOpen[`${section}-${index}`] = false;
  }

  onAvatarSelected(avatar: IAvatar): void {
    this.brandedOrgForm.get('avatar')?.setValue(avatar);
    console.log(this.brandedOrgForm.get('avatar')?.value);
  }

  onAvatarRemoved(avatar: IAvatar): void {
    this.brandedOrgForm.get('avatar')?.setValue(null);
  }

  onAvatarUpdated(avatar: IAvatar): void {
    this.brandedOrgForm.get('avatar')?.setValue(avatar);
  }


  getOrgLogo(){
    if(!this.currentOrg()?.avatars?.length) return null;
    return this.currentOrg()?.avatars[(this.currentOrg()?.avatars.length ?? 0) - 1]?.url
  }

  onPaymentCompleted(event: {
    success: boolean;
    paymentIntentId: string | null;
    discountCode?: string;
    fullyDiscounted?: boolean;
  }): void {
    if(event.success){
      this.brandedOrgForm.get('paymentIntentId')?.setValue(event.paymentIntentId);
    this.store.dispatch(OrganizationsAppActions.createSubBrandedOrganization({
      orgLink: this.orgLink,
      adminDetails: this.brandedOrgForm.get('adminDetails')?.value,
      orgDetails: this.brandedOrgForm.get('orgDetails')?.value,
        paymentIntentId: this.brandedOrgForm.get('paymentIntentId')?.value
      }))
    }else{
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Payment failed'});
    }
  }
}
