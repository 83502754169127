import { createActionGroup, props } from '@ngrx/store';
import {
  IOrganization,
  IOrganizationBase,
} from 'src/app/core/models/organization.model';
import { IAvatar } from 'src/app/core/models/user.model';
import { Pagination } from './organizations.state';

export const OrganizationsAppActions = createActionGroup({
  source: 'Organizations',
  events: {
    'Get Organizations': props<{ page?: number; limit?: number }>(),
    'Get Organization By Id': props<{ id: string }>(),
    'Search Organizations': props<{
      search: string;
      page: string;
      limit: string;
    }>(),
    'Create Organization': props<{ organization: IOrganizationBase }>(),
    'Update Organization': props<{ organization: IOrganization }>(),
    'Remove Organization': props<{ id: string }>(),
    'Update Organization Avatar': props<{
      organizationId: string;
      avatar: IAvatar;
    }>(),
    'Remove Organization Avatar': props<{
      organizationId: string;
      avatar: IAvatar;
    }>(),
    'Update Organization Status': props<{
      organizationId: string;
      isActive: boolean;
    }>(),
    'Update Organization Branding Link': props<{
      organizationId: string;
      link: string;
      brandPageContent: string;
    }>(),
    'Create User Organization': props<{
      org: Pick<
        IOrganizationBase,
        'name' | 'addresses' | 'phoneNumbers' | 'avatars' | 'paymentIntentId'
      >;
    }>(),
    'Create Sub Branded Organization': props<{
      orgLink: string;
      adminDetails: any;
      orgDetails: any;
      paymentIntentId: string;
    }>(),
  },
});

export const OrganizationsAPIActions = createActionGroup({
  source: 'Organizations',
  events: {
    'Get Organizations Success': props<{
      organizations: IOrganization[];
      pagination: Pagination;
    }>(),
    'Get Organizations Failure': props<{ message: string }>(),
    'Get Organization By Id Success': props<{ organization: IOrganization }>(),
    'Get Organization By Id Failure': props<{ message: string }>(),
    'Search Organizations Success': props<{
      organizations: IOrganization[];
      pagination: Pagination;
    }>(),
    'Search Organizations Failure': props<{ message: string }>(),
    'Create Organization Success': props<{ organization: IOrganization }>(),
    'Create Organization Failure': props<{ message: string }>(),
    'Update Organization Success': props<{ organization: IOrganization }>(),
    'Update Organization Failure': props<{ message: string }>(),
    'Remove Organization Success': props<{
      organizationId: string;
      message: string;
    }>(),
    'Remove Organization Failure': props<{ message: string }>(),
    'Update Organization Avatar Success': props<{
      organizationId: string;
      avatars: IAvatar[];
    }>(),
    'Update Organization Avatar Failure': props<{ message: string }>(),
    'Remove Organization Avatar Success': props<{
      organizationId: string;
      avatar: IAvatar;
    }>(),
    'Remove Organization Avatar Failure': props<{ message: string }>(),
    'Update Organization Status Success': props<{
      organization: IOrganization;
    }>(),
    'Update Organization Status Failure': props<{ message: string }>(),
    'Update Organization Branding Link Success': props<{
      organization: IOrganization;
      message: string;
    }>(),
    'Update Organization Branding Link Failure': props<{
      message: string;
    }>(),
    'Create User Organization Success': props<{
      organization: IOrganization;
    }>(),
    'Create User Organization Failure': props<{ message: string }>(),
    'Create Sub Branded Organization Success': props<{
      message: string;
    }>(),
    'Create Sub Branded Organization Failure': props<{ message: string }>(),
  },
});
