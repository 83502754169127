import { Component, Input, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'cap-redirection-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './redirection-page.component.html',
  styleUrls: ['./redirection-page.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('300ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class RedirectionPageComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  private destroy$ = new Subject<void>();

  message: string = 'You will be redirected shortly...';
  targetRoute: string = '/dashboard';

  /**
   * Countdown timer (in seconds) before auto-redirect.
   * Default: 5
   */
  countDown = 8;
  private countdownTimer: any;

  ngOnInit(): void {
    this.route.queryParamMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.targetRoute = params.get('targetRoute') ?? this.targetRoute;
        this.message = params.get('message') ?? this.targetRoute;
        // e.g., automatically redirect or show them on the page
      });

    this.startCountdown();
  }

  ngOnDestroy(): void {
    this.clearCountdown();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * startCountdown:
   * Begins a 5-second timer that decrements countDown every second.
   * Once countDown <= 0, navigate to targetRoute.
   */
  private startCountdown() {
    this.countdownTimer = setInterval(() => {
      this.countDown--;
      if (this.countDown <= 0) {
        this.clearCountdown();
        this.router.navigate([this.targetRoute]);
      }
    }, 1000);
  }

  /**
   * clearCountdown:
   * Clears the interval timer when component is destroyed or redirect occurs.
   */
  private clearCountdown() {
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);
      this.countdownTimer = null;
    }
  }

  /**
   * goHome:
   * Provides a manual option for the user to navigate to /dashboard immediately.
   */
  goHome(): void {
    this.router.navigate(['/dashboard']);
  }
}
