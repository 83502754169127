<div class="bg-[url('assets/images/background-center.jpg')] bg-cover bg-center min-h-screen">
    <!-- Header -->
    <div class="flex justify-between bg-slate-900/90 w-full h-16 px-4 items-center border-b border-gray-200">
        <div class="flex gap-2 items-center">
            <div class="w-8 h-8 flex items-center justify-center">
                <img [src]="getOrgLogo() ?? '/assets/images/empty-avatar.jpg'" 
                     alt="Organization logo" 
                     class="w-full h-full object-cover rounded-full animate-fade-in">
            </div>
            <span class="material-symbols-outlined text-white">handshake</span>
            <div class="w-8 h-8 flex items-center justify-center">
                <img src="/assets/images/cw-logo-light.jpg" 
                     alt="CyberWA logo" 
                     class="w-full h-full object-cover rounded-full">
            </div>
        </div>
        <div class="flex gap-2 items-center">
            <button [routerLink]="['/auth/login']" 
                    class="rounded-md bg-transparent border border-white px-4 py-1.5 text-sm font-semibold text-white hover:bg-white/10 transition-all duration-300">
                Sign In
            </button>
        </div>
    </div>
    
    <!-- Main Content -->
    <div class="flex flex-col items-center justify-center min-h-[calc(100vh-4rem-3rem)] bg-gradient-to-b from-slate-900/80 to-slate-900/95">
        <!-- Logo and Title Section -->
        <div class="flex flex-col items-center space-y-8 max-w-4xl px-4 animate-fade-in-up">
            <!-- Organization Logo Placeholder -->
            <div class="w-32 h-32 mb-6 flex items-center justify-center">
                <img [src]="getOrgLogo() ?? '/assets/images/empty-avatar.jpg'" 
                     alt="Organization logo"
                     class="w-full h-full object-cover rounded-full animate-fade-in animation-once">
            </div>
            
            <!-- Title and Subtitle -->
            <h1 class="text-5xl md:text-6xl text-center font-bold text-white mb-4 animate-fade-in-up animation-once" 
                style="animation-delay: 200ms">
                Cyber Audit Platform
            </h1>
            <p class="text-xl md:text-2xl text-center text-gray-300 mb-8 animate-fade-in-up animation-once" 
               style="animation-delay: 400ms">
                Powered by CyberWA
            </p>
            <div class="text-center text-gray-300 mb-8 animate-fade-in-up animation-once" 
               style="animation-delay: 400ms">
                {{currentOrg()?.brandPageContent}}
            </div>
            <!-- Sign Up Button -->
            <button [routerLink]="['/auth', orgLink, 'signup']" 
                    class="rounded-md bg-red-600 px-8 py-3 text-lg font-semibold text-white shadow-lg hover:bg-red-500 transform hover:scale-105 transition-all duration-300 animate-fade-in-up animation-once"
                    style="animation-delay: 600ms">
                Sign Up
            </button>
        </div>
    </div>
    
    <!-- Footer -->
    <div class="flex h-12 items-center justify-center bg-slate-900/90 text-white text-sm">
        <p>© 2025 CyberWA. All rights reserved.</p>
    </div>
</div>