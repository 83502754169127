import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { KnobModule } from 'primeng/knob';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {
  AssessmentsAPIActions,
  AssessmentsAppActions,
} from '../../state/assessments/assessments.actions';
import { ProfilesAppActions } from '../../state/profiles/profiles.actions';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilKeyChanged,
  filter,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { selectAssessments } from '../../state/assessments/assessments.selectors';
import { selectProfileById } from '../../state/profiles/profiles.selectors';
import { selectCategoryById } from '../../state/categories/categories.state';
import { Store } from '@ngrx/store';
import { Entity } from '../shared-assessment-header/shared-assessment-header.component';
import { SharedHeaderDataService } from '../../core/services/shared-header-data.service';
import { FormsModule } from '@angular/forms';
import { IAssessment } from '../../core/models/assessments.model';
import { ICategory } from '../../core/models/categories.model';
import { SharedAssessmentScrollerService } from './shared-assessment-scroller.service';
import { Actions, ofType } from '@ngrx/effects';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CommonModule } from '@angular/common';
interface AssessmentCategory {
  assessment: IAssessment;
  category: ICategory;
}

@Component({
  selector: 'cap-shared-assessment-scroller',
  standalone: true,
  imports: [
    AsyncPipe,
    CarouselModule,
    KnobModule,
    ProgressSpinnerModule,
    CommonModule,
    FormsModule,
    NgOptimizedImage,
  ],
  templateUrl: './shared-assessment-scroller.component.html',
  styleUrl: './shared-assessment-scroller.component.scss',
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
  
})
export class SharedAssessmentScrollerComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private sharedHeaderDataService = inject(SharedHeaderDataService);
  private sharedAssessmentScrollerStore = inject(
    SharedAssessmentScrollerService,
  );
  private entitySubject = new BehaviorSubject<Entity | null>(null);
  private loadTrigger$ = new BehaviorSubject<void>(undefined);
  private currentSelectedCategoryId: string | null = null;
  private navigateToNextCategory$ =
    this.sharedHeaderDataService.navigateToNextCategory$;
  private assessmentCategories: AssessmentCategory[] = [];
  isLoading$ = this.sharedAssessmentScrollerStore.isLoadingScroller$;
  currentSelectedCategory$: Observable<AssessmentCategory | null> | undefined =
    this.sharedHeaderDataService.selectedCategory$;
  assessmentCategories$: Observable<AssessmentCategory[]> = of([]);

  private subscriptions: Subscription[] = [];
  ngOnInit() {
    const navigateToNextCategorySub = this.navigateToNextCategory$.subscribe(
      (boolValue) => {
        if (boolValue && this.assessmentCategories.length > 0) {
          const currentCategoryIndex = this.assessmentCategories.findIndex(
            (cat) => cat.category._id === this.currentSelectedCategoryId,
          );
          
          // Only navigate if we're not at the last category
          if (currentCategoryIndex < this.assessmentCategories.length - 1) {
            // Simply move to the next index
            const nextCategoryIndex = currentCategoryIndex + 1;
            this.onCategorySelected(this.assessmentCategories[nextCategoryIndex]);
          }
          
          // Reset the navigation trigger
          this.sharedHeaderDataService.resetNavigateToNextCategory();
        }
      },
    );
    this.subscriptions.push(navigateToNextCategorySub);
    const updateSub = this.actions$
      .pipe(
        ofType(AssessmentsAPIActions.updateAssessmentSuccess),
        tap(() => this.loadTrigger$.next()),
      )
      .subscribe();
    this.subscriptions.push(updateSub);

    const categorySub =
      this.sharedHeaderDataService.selectedCategory$.subscribe((category) => {
        this.currentSelectedCategoryId = category?.category._id ?? null;
      });
    this.subscriptions.push(categorySub);

    const entitySub = this.sharedHeaderDataService.selectedEntity$
      .pipe(
        filter((entity) => entity !== null),
        distinctUntilKeyChanged('entityId'),
        tap((entity) => this.entitySubject.next(entity)),
        switchMap(async (entity) => this.loadData(entity)),
      )
      .subscribe(() => {});
    this.subscriptions.push(entitySub);

    const categoryLoadSub = this.loadTrigger$
      .pipe(
        switchMap(() =>
          this.entitySubject.pipe(
            switchMap((entity) => this.loadAssessmentsAndCategories(entity)),
            catchError((error) => {
              console.error('Error loading assessments and categories:', error);
              return of([]);
            }),
          ),
        ),
      )
      .subscribe((categories) => {
        this.selectInitialCategory(categories);
        this.assessmentCategories$ = of(categories);
      });
    this.subscriptions.push(categoryLoadSub);
  }

  loadData(entity: Entity) {
    const actions =
      entity.type === 'individual'
        ? AssessmentsAppActions.getAssessmentsByUserId({
            userId: entity.entityId,
          })
        : AssessmentsAppActions.getAssessmentsByOrganizationId({
            organizationId: entity.entityId,
          });

    this.store.dispatch(actions);
    this.store.dispatch(ProfilesAppActions.getProfiles());
    this.loadTrigger$.next();
  }

  private loadAssessmentsAndCategories(
    entity: Entity | null,
  ): Observable<AssessmentCategory[]> {
    return combineLatest([
      this.store.select(selectAssessments),
      this.store.select(selectProfileById(entity?.profileId ?? '')),
    ]).pipe(
      switchMap(([assessments, profile]) =>
        combineLatest(
          assessments.map((assessment) =>
            this.store
              .select(selectCategoryById(assessment.categoryId))
              .pipe(map((category) => ({ assessment, category }))),
          ),
        ).pipe(
          map((assessmentCategories) =>
            assessmentCategories
              .filter(
                (result): result is AssessmentCategory =>
                  result.category !== null,
              )
              .sort((a, b) => {
                const aOrder =
                  profile?.categories.find((c) => c.category === a.category._id)
                    ?.order ?? 0;
                const bOrder =
                  profile?.categories.find((c) => c.category === b.category._id)
                    ?.order ?? 0;
                return aOrder - bOrder;
              }),
          ),
          tap((assessmentCategories) => {
            console.log('Loaded categories:', assessmentCategories);
            this.assessmentCategories = assessmentCategories;
          }),
        ),
      ),
    );
  }
  selectInitialCategory(categories: AssessmentCategory[]) {
    const categoryToSelect =
      categories.find(
        (cat) => cat.category._id === this.currentSelectedCategoryId,
      ) || categories[0];

    if (categoryToSelect) {
      this.onCategorySelected(categoryToSelect);
    }
  }

  onCategorySelected(assessmentCategory: AssessmentCategory) {
    this.sharedHeaderDataService.setSelectedCategory(assessmentCategory);
    this.currentSelectedCategoryId = assessmentCategory.category._id;
  }

  getScoreColor(assessmentScore: number) {
    return assessmentScore === 0
      ? '#8c908d'
      : assessmentScore < 33
        ? '#DC3444'
        : assessmentScore < 66
          ? '#FFC007'
          : '#28A745';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }

  protected readonly console = console;

  showFullList = false;

  toggleView() {
    this.showFullList = !this.showFullList;
  }
}
