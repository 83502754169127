<div class="w-full flex flex-col sm:flex-row justify-between gap-4 py-2 px-4 rounded-2xl bg-white dark:bg-slate-800 border-2 border-gray-200 dark:border-gray-700">
  <div class="flex items-center">
    <div class="flex items-center">
      <div class="w-10 h-10 sm:w-12 sm:h-12 rounded-full overflow-hidden mr-3 border-2 border-gray-300">
        <img
          [ngSrc]="currentAssessment?.data?.avatars?.slice(-1)[0]?.url || 'assets/images/default-avatar.jpeg'"
          width="48"
          height="48"
          alt="Entity Avatar"
          class="w-full h-full object-cover"
        >
      </div>
    </div>
    <h1 class="text-xl sm:text-2xl font-bold dark:text-white truncate">
      {{ currentAssessment === undefined ? "" : currentAssessment.label }}
    </h1>
  </div>
  @if (isAllowedToSelectOrgs && hasSubOrgs) {
    <div class="flex justify-end">
      <div class="flex gap-2 sm:gap-3">
        <div
          class="relative flex-1"
          capClickOutsideDirective
          (clickOutside)="closeOrgSelection()"
        >
          <button
            type="button"
            (click)="toggleOrgSelection()"
            class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white bg-red-600 px-3 sm:px-5 py-2 rounded-xl w-full sm:w-auto"
            aria-expanded="false"
          >
            <span class="truncate">Select an Entity</span>
            <svg
              class="h-4 w-4 sm:h-5 sm:w-5 flex-shrink-0"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          @if (OrgSelection) {
            @if (islandingEntitySelector | async) {
              <div class="absolute z-10 mt-2 w-full sm:max-w-md right-0">
                <div class="w-full flex justify-center overflow-hidden rounded-xl bg-white dark:bg-slate-800 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                  <p-progressSpinner class="w-16 sm:w-20" animationDuration="1s"></p-progressSpinner>
                </div>
              </div>
            } @else {
              <div class="absolute z-10 mt-2 w-[28rem] sm:max-w-md right-0">
                <div class="w-full overflow-hidden rounded-xl bg-white dark:bg-slate-800 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                  <div class="p-3 sm:p-4">
                    <p-tree
                      [value]="treeNodes"
                      class="w-full"
                      [filter]="true"
                      filterPlaceholder="Search Entities"
                      scrollHeight="400px"
                      (onNodeSelect)="onNodeSelected($event)"
                      [selectionMode]="'single'"
                      [virtualScroll]="true"
                      [virtualScrollItemSize]="46"
                      [style]="{ 'max-height': '500px', overflow: 'auto' }"
                    >
                      <ng-template let-node pTemplate="individual">
                        <div class="flex gap-2 items-center">
                          <span class="material-symbols-outlined text-sm sm:text-base">person</span>
                          <span class="truncate">{{ node.label }}</span>
                        </div>
                      </ng-template>
                      <ng-template let-node pTemplate="organization">
                        <div class="flex gap-2 items-center">
                          <span class="material-symbols-outlined text-sm sm:text-base">apartment</span>
                          <span class="truncate">{{ node.label }}</span>
                        </div>
                      </ng-template>
                    </p-tree>
                  </div>
                </div>
              </div>
            }
          }
        </div>
        <p-button
          class="bg-red-600 py-1 sm:py-2 px-3 sm:px-4 border-white rounded-full text-white"
          (click)="showDialog()"
        >
          <span class="material-symbols-outlined ">account_tree</span>
        </p-button>
      </div>

      <p-dialog
        header="Entity Hierachy"
        [(visible)]="visible"
        [modal]="true"
        [style]="{ width: '95vw', maxWidth: '1200px', height: '80vh' }"
        [breakpoints]="{'960px': '75vw', '640px': '90vw'}"
        [draggable]="false"
      >
        @if (islandingEntitySelector | async) {
          <div class="w-full h-full flex justify-center items-center p-4">
            <p-progressSpinner class="w-16 sm:w-20" animationDuration="1s"></p-progressSpinner>
          </div>
        } @else {
          <ng-template pTemplate="header">
            <div class="inline-flex align-items-center justify-content-center">
              <span class="font-bold white-space-nowrap">Select Entity</span>
            </div>
          </ng-template>
          <div class="w-full h-full">
            <cap-entity-tree-visualization
              [data]="treeNodes"
              [isSysadmin]="isSysAdmin"
              (nodeSelected)="onNodeSelected($event)"
            ></cap-entity-tree-visualization>
          </div>
        }
      </p-dialog>
    </div>
  }
</div>
