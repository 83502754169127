import {
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IAssessment } from '../../core/models/assessments.model';
import { ICategory } from '../../core/models/categories.model';
import { QuestionsService } from '../../core/services/questions.service';

import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { Store } from '@ngrx/store';
import { AssessmentsAppActions } from '../../state/assessments/assessments.actions';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { KnobModule } from 'primeng/knob';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { AssessmentPageStore } from '../assessment-page.store';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SharedHeaderDataService } from '../../core/services/shared-header-data.service';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { IService } from '../../core/models/services.model';
import { IActionItem } from '../../core/models/action-items.model';
import { DividerModule } from 'primeng/divider';
import { ISecurityControl } from '../../core/models/security-controls.model';
import { SecurityControlsAppActions } from '../../state/security-controls/security-controls.actions';
import { selectAllStandards } from '../../state/security-controls/security-controls.selectors';
import { AlertActions } from '../../state/alert/alert.actions';
import { ModalActions } from '../../state/modal/modal.actions';
import { ActionItemDocsModalComponent } from '../../administration-page/categories-list/category-upsert-modal/action-item-docs-modal/action-item-docs-modal.component';
import { DownloadActionItemsDocsModalComponent } from '../download-action-items-docs-modal/download-action-items-docs-modal.component';
import { selectModalVisibility } from 'src/app/state/modal/modal.state';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { selectUserRole } from 'src/app/state/auth/auth.selectors';
import { ToggleButtonComponent } from "../../shared/toggle-button/toggle-button.component";

@Component({
  selector: 'cap-assessment-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgForOf,
    KnobModule,
    FormsModule,
    ScrollPanelModule,
    ButtonModule,
    NgIf,
    ProgressSpinnerModule,
    AsyncPipe,
    DialogModule,
    TableModule,
    DividerModule,
    JsonPipe,
    ActionItemDocsModalComponent,
    DownloadActionItemsDocsModalComponent,
    BadgeModule,
    OverlayPanelModule,
    TooltipModule,
    ToggleButtonComponent
],
  templateUrl: './assessment-form.component.html',
  styleUrl: './assessment-form.component.scss',
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: 0,
        }),
      ),
      state(
        'expanded',
        style({
          height: '*',
          opacity: 1,
        }),
      ),
      transition('collapsed <=> expanded', animate('300ms ease-in-out')),
    ]),
  ],
})
export class AssessmentFormComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private assessmentPageStore = inject(AssessmentPageStore);
  private sharedHeaderDataService = inject(SharedHeaderDataService);
  currentUserRole = this.store.select(selectUserRole);
  @Input() selectedEntityId: string | null = null;
  currentActionItemInfo: {
    questionIndex: number;
    actionItemId: string;
    event: Event;
  } | null = null;
  private currentEntityId: string = '';
  confirmActionItemChange = false;
  @Input() selectedAssessmentCategory: {
    assessment: IAssessment;
    category: ICategory;
  } | null = null;

  currentActionItemId = '';
  standards = this.store.select(selectAllStandards);
  questions: any[] = [];
  form: FormGroup;
  subscriptions: Subscription[] = [];
  expandedState: number | null = null;
  expandedTextAreas: number | null = null;
  isAssessmentFormLoading = this.assessmentPageStore.isLoadingForm$;
  private currentAssessmentCategory: string = '';
  showNextCategoryButton = false;
  showServices = false;
  showControls = false;
  services: IService[] = [];
  controls: ISecurityControl[] = [];
  currentActionItem: IActionItem | null = null;
  currentActionItemIndex: number | null = null;

  @ViewChild('op') overlayPanel!: OverlayPanel;
  selectedQuestionDescription: string | null = null;
  notApplicableReason: string = '';

  constructor(
    private fb: FormBuilder,
    private questionsService: QuestionsService, // Inject your questions service
  ) {
    this.form = this.fb.group({
      questions: this.fb.array([]),
    });
  }

  ngOnInit() {
    const entityAndCategoryChanges$ = combineLatest([
      this.sharedHeaderDataService.selectedCategory$.pipe(
        filter((category) => !!category),
        map((category) => {
          this.notApplicableReason = category.assessment.notApplicableReason;
          return category.category._id
        }),
        distinctUntilChanged(),
      ),
      this.sharedHeaderDataService.selectedEntity$.pipe(
        map((entity) => entity?.entityId),
        filter((entityId) => !!entityId),
        distinctUntilChanged(),
      ),
    ]).pipe(
      tap(([categoryId, entityId]) => {
        this.currentAssessmentCategory = categoryId;
        this.currentEntityId = entityId;
      }),
      distinctUntilChanged(
        (prev, curr) => prev[0] === curr[0] && prev[1] === curr[1],
      ),
    );

    const fetchQuestions$ = entityAndCategoryChanges$.pipe(
      switchMap(([categoryId, entityId]) => {
        this.assessmentPageStore.toggleAssessmentFormLoading(true);
        return this.questionsService
          .getQuestionsWithOptionsAndActions(categoryId)
          .pipe(
            debounceTime(300),
            catchError((error) => {
              console.error('Failed to fetch questions:', error);
              return of([]);
            }),
          );
      }),
      tap({
        next: (questions: any) => {
          this.questions = questions.questions;
          this.buildForm();
          this.assessmentPageStore.toggleAssessmentFormLoading(false);
        },
        error: (error) => console.error('Error loading questions:', error),
      }),
    );

    const subscription = fetchQuestions$.subscribe();
    this.store.dispatch(SecurityControlsAppActions.getStandards());
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  fetchQuestions(categoryId: string) {
    this.assessmentPageStore.toggleAssessmentFormLoading(true);
    const questionsSub = this.questionsService
      .getQuestionsWithOptionsAndActions(categoryId)
      .pipe(debounceTime(300)) // Debounce API call
      .subscribe((questions: any) => {
        this.questions = questions.questions;
        this.buildForm();
        this.assessmentPageStore.toggleAssessmentFormLoading(false);
      });
    this.subscriptions.push(questionsSub);
  }

  get questionsFormArray(): FormArray {
    return this.form.get('questions') as FormArray;
  }

  buildForm() {
    this.questionsFormArray.clear();
    this.questions.forEach((question) => {
      const existingResponse =
        this.selectedAssessmentCategory?.assessment.questionsResponses.find(
          (response) => response.questionId === question._id,
        );

      const actionItemsIds = existingResponse?.actionItemsIds || [];
      const actionItemsFormArray = this.fb.array(
        actionItemsIds.map((id) => this.fb.control(id)), // *******
      );

      const questionGroup = this.fb.group({
        questionId: [question._id, Validators.required],
        optionId: [existingResponse?.optionId || null, Validators.required],
        actionItemsIds: actionItemsFormArray, // *******
        textResponse: [existingResponse?.textResponse || ''],
      });
      this.showNextCategoryButton = this.checkAnswerdOptions();
      this.questionsFormArray.push(questionGroup);
    });
  }

  onActionItemChange(
    questionIndex: number,
    actionItemId: string,
    event: Event,
  ) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;
    const actionItemsIds = this.questionsFormArray
      .at(questionIndex)
      .get('actionItemsIds') as FormArray;

    if (isChecked) {
      actionItemsIds.push(this.fb.control(actionItemId));
    } else {
      // Remove the action item from the form array
      const index = actionItemsIds.controls.findIndex(
        (x) => x.value === actionItemId,
      );
      if (index !== -1) {
        actionItemsIds.removeAt(index);
      }
    }
    this.onSubmit();
  }

  onOptionChange(questionIndex: number, optionId: string) {
    const question = this.questions[questionIndex];
    const actionItemsIds = this.questionsFormArray
      .at(questionIndex)
      .get('actionItemsIds') as FormArray;
    for (let i = actionItemsIds.length - 1; i >= 0; i--) {
      const actionItemId = actionItemsIds.at(i).value;
      const actionItem = question.actionItems.find(
        (item: any) => item._id === actionItemId && item.isCompleted,
      );
      if (actionItem) {
        actionItemsIds.removeAt(i);
      }
    }

    // Automatically check action items with isCompleted set to true if the selected option is positive
    const selectedOption = question.options.find(
      (option: any) => option._id === optionId,
    );
    if (selectedOption && selectedOption.isPositive) {
      question.actionItems.forEach((actionItem: any) => {
        if (actionItem.isCompleted) {
          actionItemsIds.push(this.fb.control(actionItem._id));
        }
      });
    }
    this.onSubmit();
    this.showNextCategoryButton = this.checkAnswerdOptions();
    this.expandedState = questionIndex;
  }
  onCommentSave(i: number) {
    this.onSubmit();
    this.store.dispatch(
      AlertActions.addAlert({
        alert: {
          type: 'success',
          message: 'Comment saved successfully',
        },
      }),
    );
    this.expandedTextAreas = i;
  }
  toggleActionItems(index: number) {
    if (this.expandedState === index) {
      this.expandedState = null;
    } else {
      this.expandedState = index;
    }
  }
  toggleTextArea(index: number) {
    if (this.expandedTextAreas === index) {
      this.expandedTextAreas = null;
    } else {
      this.expandedTextAreas = index;
    }
  }

  onSubmit() {
    this.store.dispatch(
      AssessmentsAppActions.updateAssessment({
        assessmentId: this.selectedAssessmentCategory!.assessment._id,
        questionsResponses: this.form.value.questions,
      }),
    );
  }

  checkAnswerdOptions() {
    let allAnswered = true;
    this.questionsFormArray.controls.forEach((question) => {
      if (!question.get('optionId')?.value) {
        allAnswered = false;
      }
    });
    return allAnswered;
  }

  NavigateToNextCategory() {
    this.sharedHeaderDataService.onNavigateToNextCategory();
  }

  onShowService(actionItem: any) {
    this.showServices = !this.showServices;
    this.services = actionItem.services;
  }
  onShowControls(actionItem: any) {
    this.showControls = !this.showControls;
    this.controls = actionItem.controls;
  }

  openDownloadDocsModal(actionItemId: string) {
    this.currentActionItemId = actionItemId;
    this.store.dispatch(ModalActions.open({ id: 'download-action-item-docs' }));
  }

  selectModalVisibilityById(id: string) {
    return this.store.select(selectModalVisibility(id));
  }

  findStandard(standardId: string) {
    return this.standards.pipe(
      map((standards) =>
        standards.find((standard) => standard?._id === standardId),
      ),
    );
  }
  onAttachEvidence(actionItem: IActionItem, index: number) {
    this.currentActionItem = actionItem;
    this.currentActionItemIndex = index;
    // Open the modal or perform any other necessary actions
    this.store.dispatch(ModalActions.open({ id: 'action-item-docs' }));
  }

  onModalClosed() {
    this.currentActionItem = null;
    this.currentActionItemIndex = null;
    // Perform any other necessary cleanup
  }

  getNumberOfEvidance(id: string): number {
    const evidence = this.selectedAssessmentCategory?.assessment.evidence;
    if (!evidence) {
      return 0;
    }
    const actionItemEvidence = evidence.find(ev => ev.actionItemId === id);
    if (!actionItemEvidence || !actionItemEvidence.doc) {
      return 0;
    }
    return actionItemEvidence.doc.length;
  }
  getNumberOfDocs(id: string): number {
    const actionItem = this.questions
      .flatMap(question => question.actionItems)
      .find(item => item._id === id);
    
    if (!actionItem || !actionItem.docs) {
      return 0;
    }
    
    return actionItem.docs.length;
  }

  showQuestionDescription(index: number) {
    this.selectedQuestionDescription = this.questions[index].questionDescription || null;
    this.overlayPanel.toggle(event);
  }
  isNegativeOption(index: number): boolean {
    const selectedOptionId = this.questionsFormArray.at(index).get('optionId')?.value;
    const selectedOption = this.questions[index].options.find((option: any) => option._id === selectedOptionId);
    // Check if the selected option exists and is not positive
    return selectedOption ? !selectedOption.isPositive : false;
  }

  onNotApplicableChange(event: any) {
    this.store.dispatch(AssessmentsAppActions.setAssessmentAsNotApplicable({
      assessmentId: this.selectedAssessmentCategory!.assessment._id,
      isNotApplicable: event
    }));
  }
  onNotApplicableReasonChange(event: any) {
    this.notApplicableReason = event;
    this.store.dispatch(AssessmentsAppActions.setAssessmentNotApplicableReason({
      assessmentId: this.selectedAssessmentCategory!.assessment._id,
      notApplicableReason: event
    }))
  }
}
