<div
  [@fadeSlideInOut]
  class="bg-gray-50 dark:bg-gray-900 flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8"
>
  <img
    [@growAndCenterLogo]="
      (formSubmissionSuccess$ | async) ? 'centered' : 'normal'
    "
    class="mx-auto h-10 w-auto logo"
    src="assets/images/cw-logo-light.svg"
    alt="CyberWa Logo"
  />

  @if (!(formSubmissionSuccess$ | async)) {
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <h2
      class="mt-6 text-center text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-white"
    >
      Forgot password
    </h2>
    <p class="mt-8 text-left text-base font-medium leading-7 text-gray-900 dark:text-white">
      Enter your email address and we'll send you a link to reset your password
    </p>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="bg-white dark:bg-gray-800 px-6 py-12 shadow sm:rounded-lg sm:px-12">
      <form class="space-y-6" [formGroup]="form" (ngSubmit)="onFormSubmit()">
        @if (formError$ | async) {
        <div class="rounded-md bg-red-50 p-4" role="alert">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg
                class="h-5 w-5 text-red-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800 dark:text-red-400">
                {{ formError$ | async }}
              </h3>
            </div>
          </div>
        </div>
        }
        <div>
          <label
            for="email"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >Email address</label
          >
          <div class="mt-2 relative">
            <input
              id="email"
              name="email"
              type="email"
              placeholder="you@example.com"
              autocomplete="email"
              formControlName="email"
              class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 dark:bg-slate-800"
            />
            <div
              class="pointer-events-none absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              <svg
                class="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z"
                />
                <path
                  d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z"
                />
              </svg>
            </div>
          </div>
          <!-- Email error message -->
          @if(form.controls['email'].invalid && form.controls['email'].touched)
          {
          <div class="text-red-700">
            @if(form.controls['email'].errors?.['required']) {
            <p>Email is required.</p>
            } @if(form.controls['email'].errors?.['email']) {
            <p>Please enter a valid email.</p>
            }
          </div>
          }
        </div>

        <div>
          <button
            type="submit"
            [disabled]="!form.valid"
            class="flex w-full justify-center items-center rounded-md bg-primary-600 hover:bg-primary-500 disabled:bg-primary-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primatext-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            @if (loading$ | async) {

            <svg
              aria-hidden="true"
              role="status"
              class="inline w-4 h-4 mr-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>

            } Send email
          </button>
        </div>
      </form>
    </div>

    <p class="mt-10 text-center text-sm text-gray-500">
      want to cancel?
      <a
        routerLink="/auth/login"
        class="font-semibold leading-6 text-primary-600 hover:text-primary-500"
        >Back to login page</a
      >
    </p>
  </div>
  } @else {

  <div class="text-center mt-10">
    <h2 class="text-2xl font-bold">Check your email</h2>
    <p class="mt-4 text-lg font-medium">
      We just sent an email to you with a link to reset your password!
    </p>
  </div>

  }
</div>
