import { createSelector } from '@ngrx/store';

import {
  OrganizationsState,
  selectAll as selectAllOrganizations,
  selectOrganizationById,
  selectOrganizationsState,
} from './organizations.state';
import { IAvatar } from 'src/app/core/models/user.model';
import { IOrganization } from 'src/app/core/models/organization.model';

function selectCurrentAvatar(organization: IOrganization): IAvatar | undefined {
  return organization.avatars.find((avatar) => avatar.isCurrent);
}

export const selectOrganizationCurrentAvatar = (id: string) =>
  createSelector(selectOrganizationById(id), (organization): IAvatar | null => {
    // Use a ternary operator to explicitly return null if the find method returns undefined
    return organization?.avatars
      ? organization.avatars.find((av) => av.isCurrent) ?? null
      : null;
  });

export const selectOrganizationBrandingLinkById = (id: string) =>
  createSelector(selectOrganizationById(id), (organisation): {link: string, brandPageContent: string} | null => {
    return {link: organisation?.link ?? '', brandPageContent: organisation?.brandPageContent ?? ''} ?? null;
  });

export const selectPagination = createSelector(
  selectOrganizationsState,
  (organizations: OrganizationsState) => organizations.pagination,
);
export const selectOrganiationNameById = (id: string) =>
  createSelector(selectOrganizationById(id), (organization): string | null => {
    return organization?.name ?? null;
  });

export const selectOrganizationsWithCurrentAvatar = createSelector(
  selectAllOrganizations, // from organizationsFeature
  (organizations) =>
    organizations.map((org) => ({
      ...org,
      avatar: selectCurrentAvatar(org),
    })),
);

export const selectBrandedOrganizations = createSelector(
  selectAllOrganizations,
  (organizations) => organizations.filter((org) => org.isBranded),
);

export const selectOldAvatarsByOrganizationId = (id: string) =>
  createSelector(selectOrganizationById(id), (organization) => {
    // Check if the organization exists and has avatars
    if (organization && organization.avatars) {
      // Filter avatars to exclude the current one
      return organization.avatars.filter((avatar) => !avatar.isCurrent);
    }
    // Return an empty array if no old avatars or the organization doesn't exist
    return [];
  });

export const selectPaginatedOrganizations = createSelector(
  selectAllOrganizations,
  selectPagination,
  (organizations, pagination) => {
    const start = (pagination.page - 1) * pagination.limit;
    const end = start + pagination.limit;
    return organizations.slice(start, end);
  },
);

export const selectOrganizations = createSelector(
  selectAllOrganizations,
  (state) => state,
);

export const selectHasSubOrganizations = createSelector(
  selectAllOrganizations, // your base organizations state selector
  (organizations) => organizations?.length > 1 // adjust based on your state structure
);
