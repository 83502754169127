import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from "@angular/forms";
import { Directive, inject } from "@angular/core";
import { map, catchError, Observable, of, tap, delay } from "rxjs";
import { UsersService } from "../../core/services/users.service";

@Directive({
    selector: '[isEmailUnique]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useClass: IsEmailUniqueValidatorDirective,
        multi: true
    }]
})
export class IsEmailUniqueValidatorDirective implements AsyncValidator {
    private userService = inject(UsersService);
    
    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        const email = control.value;
        if (!email) {
            return of(null);
        }
        
        return this.userService.checkIfEmailExists(email).pipe(
            map(res => {
                if (res.message) {
                    control.setErrors({ emailExists: true });
                    return { emailExists: true };
                }
                return null;
            }),
            catchError(() => of(null))
        );
    }

    static createValidator(userService: UsersService) {
        return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
            const email = control.value;
            if (!email) {
                return of(null);
            }
            
            return userService.checkIfEmailExists(email).pipe(
                map(res => {
                    if (res.message) {
                        control.setErrors({ emailExists: true });
                        return { emailExists: true };
                    }
                    return null;
                }),
                catchError(() => of(null))
            );
        };
    }
}