<ng-container class="min-h-screen w-full mb-3">
  <div class="w-full">
    <cap-shared-assessment-scroller></cap-shared-assessment-scroller>
  </div>
  @if (showStats) {
    @if(!currentSelectedCategory?.assessment?.isNotApplicable){
    <div class="h-full w-full mt-2 flex flex-col bg-white dark:bg-slate-800 p-4 md:p-6 rounded-lg shadow-md">
      <!-- Toggle button for graphs -->
      <button 
        (click)="toggleGraphs()" 
        class="mb-4 px-4 py-2 border border-red-600 text-black dark:text-white rounded hover:bg-red-600 transition-colors duration-300 flex items-center"
      >
        <span class="mr-2 text-black dark:text-white">{{ showGraphs() ? 'Hide' : 'Show' }} Graphs</span>
        <span class="material-symbols-outlined">{{ showGraphs() ? 'expand_less' : 'expand_more' }}</span>
      </button>

      <!-- Collapsible graphs section -->
      @if (showGraphs()) {
        <div class="w-full h-full mb-8">
          <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <!-- Questions Pie Chart -->
            <div class="bg-white dark:bg-slate-700 rounded-xl shadow-lg p-4 md:p-6 transition-all duration-300 hover:shadow-xl min-h-[400px] flex flex-col">
              <h2 class="text-xl font-semibold mb-4 text-center text-gray-800 dark:text-white">Questions</h2>
              <div class="flex-grow flex items-center justify-center">
                <p-chart
                  type="pie"
                  [data]="this.currentAssessmentStats.questionsGraphData"
                  [options]="options"
                />
              </div>
            </div>
            
            <!-- Action Items Pie Chart -->
            <div class="bg-white dark:bg-slate-700 rounded-xl shadow-lg p-4 md:p-6 transition-all duration-300 hover:shadow-xl min-h-[400px] flex flex-col">
              <h2 class="text-xl font-semibold mb-4 text-center text-gray-800 dark:text-white">Action Items</h2>
              <div class="flex-grow flex items-center justify-center">
                <p-chart
                  type="pie"
                  [data]="this.currentAssessmentStats.actionItemsGraphData"
                  (onDataSelect)="onDataSelectActionItem($event)"
                  [options]="options"
                />
              </div>
            </div>
            
            <!-- Score Progression Line Chart -->
            <div class="bg-white dark:bg-slate-700 rounded-xl shadow-lg p-4 md:p-6 transition-all duration-300 hover:shadow-xl min-h-[400px] flex flex-col">
              <h2 class="text-xl font-semibold mb-4 text-center text-gray-800 dark:text-white">Score Progression</h2>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Select Score Range</label>
              <p-dropdown
                [options]="dataPointOptions"
                optionLabel="name"
                (onChange)="onSelectDataPoints($event.value)"
                class="w-full mb-4"
                styleClass="w-full"
              ></p-dropdown>
              <div class="flex-grow w-full flex items-center justify-center">
                <p-chart
                  type="line"
                  [data]="this.currentAssessmentStats.scoreProgressionData"
                  [options]="this.scoreProgressionChartOptions"
                />
              </div>
            </div>
          </div>
        </div>
      }
      
      <!-- Score History and Action Items -->
      <div class="flex-grow">
        <p-tabView styleClass="tabview-custom" [(activeIndex)]="activeIndexTab">
          <p-tabPanel header="Assessment History">
            <ng-template pTemplate="header">
              <div class="flex items-center gap-2" [ngClass]="{ 'text-red-600': activeIndexTab === 0 }">
                <span class="material-symbols-outlined">query_stats</span>
                <span class="font-bold whitespace-nowrap">Assessment History</span>
              </div>
            </ng-template>
            <div *ngIf="currentAssessmentStats?.scoreHistory?.length" class="w-full overflow-x-auto">
              <p-table
                [value]="this.currentAssessmentStats.scoreHistory"
                styleClass="p-datatable-gridlines p-datatable-sm"
                [paginator]="true"
                [rows]="10"
                [rowsPerPageOptions]="[5, 10, 20]"
                [tableStyle]="{ 'min-width': '50rem' }"
                responsiveLayout="scroll"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th
                      scope="col"
                      class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      pSortableColumn="userName"
                    >
                      <span class="flex items-center">
                        User Name <p-sortIcon field="userName"></p-sortIcon>
                      </span>
                    </th>
                    <th
                      pSortableColumn="userRole"
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <span class="flex items-center">
                        User Role <p-sortIcon field="userRole"></p-sortIcon>
                      </span>
                    </th>
                    <th
                      pSortableColumn="score"
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <span class="flex items-center">
                        Score<p-sortIcon field="score"></p-sortIcon>
                      </span>
                    </th>
                    <th
                      pSortableColumn="timestamp"
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <span class="flex items-center">
                        Date<p-sortIcon field="timestamp"></p-sortIcon>
                      </span>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                  <tr
                    (mouseover)="onHoverActionItem(product)"
                    (mouseleave)="onLeaveActionItem()"
                    (focus)="onHoverActionItem(product)"
                    (blur)="onLeaveActionItem()"
                    tabindex="0"
                    class="hover:bg-gray-50 dark:hover:bg-slate-700 transition-colors duration-150"
                  >
                    <td
                      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-600 dark:text-white sm:pl-6"
                    >
                      {{ product.userName }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300"
                    >
                      {{ convertUserRoleName(product.userRole) }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300"
                    >
                      {{ product.score.toFixed(0) + "%" }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300"
                    >
                      {{ product.timestamp | date: "medium" }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Action Items">
            <ng-template pTemplate="header">
              <div class="flex items-center gap-2" [ngClass]="{ 'text-red-600': activeIndexTab === 1 }">
                <span class="material-symbols-outlined">playlist_remove</span>
                <span class="font-bold whitespace-nowrap">Action Items</span>
              </div>
            </ng-template>
            <div *ngIf="showUncompletedItems" class="w-full overflow-x-auto">
              <p-table
                [value]="selectedActionItems"
                styleClass="p-datatable-gridlines p-datatable-sm"
                [paginator]="true"
                [loading]="isTableLoading"
                [rows]="10"
                [rowsPerPageOptions]="[5, 10, 20]"
                [tableStyle]="{ 'min-width': '50rem' }"
                responsiveLayout="scroll"
                [sortField]="'weight'"
                [sortOrder]="-1"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="text" class="text-left">
                      <span class="flex items-center">
                        Action Item <p-sortIcon field="text"></p-sortIcon>
                      </span>
                    </th>
                    <th pSortableColumn="weight" class="text-left">
                      <span class="flex items-center">
                        Weight <p-sortIcon field="weight"></p-sortIcon>
                      </span>
                    </th>
                    <th class="text-left"><span class="flex">Status</span></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                  <tr
                    (mouseover)="onHoverActionItem(product)"
                    (mouseleave)="onLeaveActionItem()"
                    (focus)="onHoverActionItem(product)"
                    (blur)="onLeaveActionItem()"
                    tabindex="0"
                    class="hover:bg-gray-50 dark:hover:bg-slate-700 transition-colors duration-150"
                  >
                    <td class="text-sm text-gray-600 dark:text-gray-300">{{ product.text }}</td>
                    <td class="text-sm text-gray-600 dark:text-gray-300">{{ convertWeight(product.weight) }}</td>
                    <td>
                      <div class="flex h-6 items-center">
                        <input
                          id="candidates"
                          aria-describedby="candidates-description"
                          (change)="showConfirmationDialog(product)"
                          [checked]="selectedLabel === 'Completed'"
                          name="candidates"
                          type="checkbox"
                          class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600 cursor-pointer"
                        />
                      </div>
                    </td>
                  </tr>
                  <td class="relative">
                    <div
                      *ngIf="hoveredActionItem === product"
                      class="absolute top-full -mt-28 left-0 bg-white dark:bg-slate-700 text-black dark:text-white p-2 border border-gray-300 dark:border-gray-600 rounded shadow-lg z-10"
                    >
                      <p>{{ hoveredQuestion }}</p>
                    </div>
                  </td>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  }@else {
    <div class="w-full h-32 my-2 flex justify-center items-center bg-white dark:bg-slate-800 border-2 rounded-2xl border-gray-200 dark:border-gray-700">
      <h1 class="text-lg font-bold ml-2">Category has been disabled</h1>
    </div>
  }
  } @else {
    <div class="w-full h-full flex justify-center items-center">
      <p-progressSpinner></p-progressSpinner>
    </div>
  }

  <p-dialog
    header="Confirm Action"
    [(visible)]="displayConfirmation"
    modal="modal"
    [closable]="false"
    class="p-4"
  >
    <p class="mb-4">Are you sure you want to change the status of this action item?</p>
    <p-footer class="flex justify-end gap-4">
      <button
        type="button"
        class="rounded bg-red-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors"
        (click)="confirmChangeStatus()"
      >
        Yes
      </button>
      <button
        type="button"
        class="rounded bg-gray-200 px-6 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition-colors"
        (click)="cancelChangeStatus()"
      >
        No
      </button>
    </p-footer>
  </p-dialog>
</ng-container>