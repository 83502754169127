import { Component, inject, signal } from '@angular/core';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { TableComponent } from '../../../shared/table/table.component';
import { IColumn } from '../../../core/models/column.model';
import { Store } from '@ngrx/store';
import { selectProfiles } from '../../../state/profiles/profiles.selectors';
import { map, Subject, takeUntil, tap } from 'rxjs';
import { convertProfileToProfileTable } from '../../../utils/entity-formatting.util';
import {
  ProfilesApiActions,
  ProfilesAppActions,
} from '../../../state/profiles/profiles.actions';
import { AsyncPipe } from '@angular/common';
import { Actions, ofType } from '@ngrx/effects';
import { ModalActions } from '../../../state/modal/modal.actions';
import { selectProfileById } from '../../../state/profiles/profiles.state';
import { IModalProfileMetadata } from '../individuals-profiles/individuals-profiles.component';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { OrganizationalProfileUpsertComponent } from './organizational-profile-upsert/organizational-profile-upsert.component';
import { selectModalVisibility } from '../../../state/modal/modal.state';
import { OrganizationProfileUpsertStore } from './organizational-profile-upsert/organizational-profile-upsert.store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'cap-organizational-profiles',
  standalone: true,
  providers: [OrganizationProfileUpsertStore],
  imports: [
    SpinnerComponent,
    TableComponent,
    AsyncPipe,
    ConfirmModalComponent,
    OrganizationalProfileUpsertComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './organizational-profiles.component.html',
  styleUrl: './organizational-profiles.component.scss',
})
export class OrganizationalProfilesComponent {
  private destroy$ = new Subject<void>();
  private actions$ = inject(Actions);
  searchQuery = '';
  profileDropped: any;
  private readonly store = inject(Store);
  modalMetaData!: IModalProfileMetadata;
  isLoadingProfileList = signal(true);
  profiles$ = this.store.select(selectProfiles);
  readonly confirmModalData = {
    title: 'Delete Profile',
    message: 'Are you sure you want to delete this profile?',
  };
  organizationProfiles$ = this.profiles$.pipe(
    map((profiles) => profiles.map(convertProfileToProfileTable)),
  );
  organizationProfileColumns: IColumn[] = [
    {
      key: 'name',
      label: 'Name',
      type: 'text',
      sortable: true,
    },
    {
      key: 'categories',
      label: 'Categories',
      type: 'text',
      sortable: false,
    },
    {
      key: 'associatedOrganizations',
      label: 'Organizations',
      type: 'text',
      sortable: false,
    },
    {
      key: 'isEnabled',
      label: 'Enabled',
      type: 'button',
      sortable: false,
    },
    {
      key: 'isPrivate',
      label: 'Private',
      type: 'button',
      sortable: false,
    },
  ];

  ngOnInit() {
    this.store.dispatch(ProfilesAppActions.getOrganizationalProfiles());
    this.actions$
      .pipe(
        ofType(
          ProfilesAppActions.getOrganizationalProfiles,
          ProfilesApiActions.getProfilesSuccess,
          ProfilesApiActions.getProfilesFailure,
        ),
        tap((action) =>
          this.isLoadingProfileList.set(
            action.type === ProfilesAppActions.getOrganizationalProfiles.type,
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  openCreateProfileModal() {
    this.modalMetaData = {
      modalID: 'add-organization-profile',
      modalWidth: 'sm:max-w-7xl',
      title: 'Create Organizational Profile',
      isEditMode: false,
      profile: null,
    };
    this.store.dispatch(ModalActions.open({ id: 'add-organization-profile' }));
  }

  openConfirmationModel(profile: any) {
    this.profileDropped = profile;
    this.store.dispatch(ModalActions.open({ id: 'confirm-delete-profile' }));
  }

  openUpdateProfileModal(id: string) {
    this.store
      .select(selectProfileById(id))
      .pipe(
        map((profile) => {
          if (profile) {
            this.modalMetaData = {
              modalID: 'update-organization-profile',
              modalWidth: 'sm:max-w-7xl',
              title: 'Update Organizational Profile',
              isEditMode: true,
              profile,
            };
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
    this.store.dispatch(
      ModalActions.open({ id: 'update-organization-profile' }),
    );
  }

  protected readonly selectModalVisibility = selectModalVisibility;

  selectModalVisibilityById(profileModal: string) {
    return this.store.select(this.selectModalVisibility(profileModal));
  }

  removeProfile() {
    if (this.profileDropped) {
      this.store.dispatch(
        ProfilesAppActions.deleteProfile({ id: this.profileDropped._id }),
      );
      this.store.dispatch(ProfilesAppActions.getOrganizationalProfiles());
    }
  }
  UpdateEnableStatus($event: any) {
    const { effectedEntity, value, type } = $event;
    if (type === 'isEnabled') {
      //use effectedEnitity to dispatch the enable profile actions
      this.store.dispatch(
        ProfilesAppActions.enableProfile({
          id: effectedEntity,
          isEnabled: value,
        }),
      );
    } else if (type === 'isPrivate') {
      this.store.dispatch(
        ProfilesAppActions.toggleProfilePrivate({
          id: effectedEntity,
        }),
      );
    }
  }
  searchProfiles() {
    this.store.dispatch(
      ProfilesAppActions.searchProfiles({
        search: this.searchQuery,
        profileType: 'organizational',
      }),
    );
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
