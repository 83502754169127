import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable, Subscription, map, of } from 'rxjs';
import * as QRCode from 'qrcode';

import { selectUser } from 'src/app/state/auth/auth.state';

@Component({
  selector: 'cap-two-factor-setup-auth',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './two-factor-auth-setup.component.html',
  styleUrl: './two-factor-auth-setup.component.scss',
})
export class TwoFactorAuthSetupComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private subs: Subscription[] = [];

  user$ = this.store.select(selectUser);
  qrCode$: Observable<string> = of('');

  ngOnInit(): void {
    const sub = this.user$
      .pipe(
        map(async (user) => {
          console.log('[TOW_FACTOR_AUTH_SETUP] user : ', user);
          if (user && user.otpAuthUrl) {
            try {
              const url = await QRCode.toDataURL(user.otpAuthUrl);
              this.qrCode$ = of(url);
            } catch (error) {
              console.error('Error generating QR code', error);
            }
          }
        }),
      )
      .subscribe();
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
