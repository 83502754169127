<div class="flex flex-col px-4 py-6 gap-y-5">
  @if (selectedAvatar$ | async) {
    <img
      class="h-auto max-w-lg rounded-lg"
      [src]="
        (selectedAvatar$ | async)?.data
          ? (selectedAvatar$ | async)?.data
          : 'assets/images/default-avatar.jpeg'
      "
      alt="User Avatar"
    />
  } @else if (currentAvatar$ | async) {
    <img
      class="h-auto max-w-lg rounded-lg"
      [src]="
        (selectedAvatar$ | async)?.data
          ? (selectedAvatar$ | async)?.data
          : 'assets/images/default-avatar.jpeg'
      "
      alt="User Avatar"
    />
  } @else {
    <img
      class="h-auto max-w-lg rounded-lg"
      src="assets/images/default-avatar.jpeg"
      alt="User Avatar"
    />
  }

  <ul
    role="list"
    class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
  >
    @for (avatar of oldAvatars$ | async; track avatar?.url) {
      <li class="group">
        <div
          class="relative aspect-h-7 aspect-w-10 block w-full rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
        >
          <img
            [src]="avatar.url"
            alt="user-avatar"
            class="pointer-events-none object-cover group-hover:opacity-75"
          />
          <button
            type="button"
            (click)="selectOldAvatar(avatar)"
            class="absolute inset-0 focus:outline-none"
          >
            <span class="sr-only">Select avatar</span>
          </button>

          <button
            type="button"
            (click)="callConfirmationModal(avatar)"
            class="absolute inline-flex items-center justify-center w-6 h-6 -top-3 -end-3 p-4 rounded-full border-2 border-white dark:border-gray-800 bg-primary-600 text-gray-50 hover:bg-primary-500 max-w-fit max-h-fit opacity-0 group-hover:opacity-100"
            *ngIf="avatar !== (selectedAvatar$ | async)"
          >
            <span class="material-symbols-outlined"> delete </span>
          </button>
        </div>
      </li>
    }
  </ul>
  @if (!(oldAvatars$ | async)) {
    <div>
      <p
        class="text-base text-center font-normal text-primary-500 dark:text-primary-500"
      >
        You haven't uploaded any avatars yet
      </p>
      <p
        class="text-sm text-center font-normal text-primary-500 dark:text-primary-500"
      >
        (your old avatars will be shown here)
      </p>
    </div>
  }

  <div
    capDnd
    (fileDropped)="onFileDropped($event)"
    class="flex items-center justify-center w-full"
  >
    <label
      for="dropzone-file"
      class="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
    >
      <div class="flex flex-col items-center justify-center pt-5 pb-6">
        <svg
          class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 16"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
          />
        </svg>
        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
          <span class="font-semibold">Click to upload</span> or drag and drop
        </p>
        <p class="text-xs text-gray-500 dark:text-gray-400">
          SVG, PNG, JPG or GIF (MAX. {{ maxDimensions.width }}x{{
            maxDimensions.height
          }}px)
        </p>
      </div>
      <input
        #fileInput
        id="dropzone-file"
        type="file"
        class="hidden"
        (change)="onFileSelected(fileInput.files)"
      />
    </label>
  </div>
</div>

<cap-confirm-modal
  [modalID]="'confirm-removing-avatar'"
  [data]="confirmModalData"
  (confirmEvent)="removeAvatar()"
></cap-confirm-modal>
