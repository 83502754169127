import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil, firstValueFrom, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

import { IAvatar } from 'src/app/core/models/user.model';
import { SharedHeaderDataService } from 'src/app/core/services/shared-header-data.service';
import { FormErrorMessageComponent } from 'src/app/shared/form-error-message/form-error-message.component';
import { SharedAssessmentHeaderComponent } from 'src/app/shared/shared-assessment-header/shared-assessment-header.component';
import { OrganizationsAppActions } from 'src/app/state/organizations/organizations.actions';
import {
  selectOrganizationBrandingLinkById,
  selectOrganizationsWithCurrentAvatar,
} from 'src/app/state/organizations/organizations.selectors';
import { BrandingPageStore } from './branding-page.store';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';

@Component({
  selector: 'cap-branding-page',
  standalone: true,
  providers: [BrandingPageStore],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedAssessmentHeaderComponent,
    FormErrorMessageComponent,
    SpinnerComponent,
  ],
  templateUrl: './branding-page.component.html',
  styleUrls: ['./branding-page.component.scss'],
})
export class BrandingPageComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private componentStore = inject(BrandingPageStore);
  private fb = inject(FormBuilder);
  private sharedHeaderDataService = inject(SharedHeaderDataService);
  entity$ = this.sharedHeaderDataService.selectedEntity$;
  // private existingBrandDetails$?: IBrandDetails;
  private destroy$ = new Subject<void>();

  appUrl = environment.appUrl;

  dropDownProfileOptions: any[] = [];

  logos$!: Observable<IAvatar[]>;
  organizationsWithAvatar$ = this.store.select(
    selectOrganizationsWithCurrentAvatar,
  );

  loading$ = this.componentStore.isLoading$;

  brandDetailsForm!: FormGroup;

  defaultAvatarUrl = 'assets/images/default-avatar.jpeg'; // Path to default avatar image

  ngOnInit(): void {
    // Initialize the form
    this.brandDetailsForm = this.fb.group({
      link: ['', Validators.required],
      brandPageContent: [''],
    });

    this.store.dispatch(OrganizationsAppActions.getOrganizations({}));

    // Subscribe to entity$ to set initial form values
    this.entity$.pipe(takeUntil(this.destroy$)).subscribe((entity) => {
      // Set initial form values based on the entity's brandDetails
      this.store
        .select(selectOrganizationBrandingLinkById(entity.entityId))
        .pipe(takeUntil(this.destroy$))
        .subscribe((link) => {
          if (link?.link) {
            this.brandDetailsForm.patchValue({
              link: link.link,
              brandPageContent: link.brandPageContent,
            });
          } else {
            this.brandDetailsForm.reset();
          }
        });
    });
  }

  async onSubmit() {
    if (this.brandDetailsForm.invalid) {
      this.brandDetailsForm.markAllAsTouched();
      return;
    }

    const entity = await firstValueFrom(this.entity$);

    this.store.dispatch(
      OrganizationsAppActions.updateOrganizationBrandingLink({
        organizationId: entity.entityId,
        ...this.brandDetailsForm.value,
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
