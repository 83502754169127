import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedHeaderDataService {
  private selectedEntitySubject = new BehaviorSubject<any>(null);
  private selectedCategorySubject = new BehaviorSubject<any>(null);
  private _navigateToNextCategory = new BehaviorSubject<boolean>(false);

  selectedEntity$ = this.selectedEntitySubject.asObservable();
  selectedCategory$ = this.selectedCategorySubject.asObservable();
  navigateToNextCategory$ = this._navigateToNextCategory.asObservable();

  setSelectedEntity(entity: any) {
    this.selectedEntitySubject.next(entity);
  }

  setSelectedCategory(category: any) {
    this.selectedCategorySubject.next(category);
  }
  onNavigateToNextCategory() {
    this._navigateToNextCategory.next(true);
  }

  resetNavigateToNextCategory() {
    this._navigateToNextCategory.next(false);
  }
}
