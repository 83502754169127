import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  inject,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subject, takeUntil, tap } from 'rxjs';

import { ModalContainerComponent } from 'src/app/shared/modal-container/modal-container.component';
import { selectActionItemById } from 'src/app/state/action-items/action-items.state';
import { FileWithPreview, IDoc } from 'src/app/core/models/action-items.model';
import { ModalActions } from 'src/app/state/modal/modal.actions';
import { ConfirmModalComponent } from 'src/app/shared/confirm-modal/confirm-modal.component';
import { ActionItemsAppActions } from 'src/app/state/action-items/action-items.actions';
import { AssessmentsAppActions } from '../../../../state/assessments/assessments.actions';
import { selectAssessmentById } from '../../../../state/assessments/assessments.state';

@Component({
  selector: 'cap-action-item-docs-modal',
  standalone: true,
  imports: [CommonModule, ModalContainerComponent, ConfirmModalComponent],
  templateUrl: './action-item-docs-modal.component.html',
  styleUrls: ['./action-item-docs-modal.component.scss'],
})
export class ActionItemDocsModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() actionItemId = '';
  @Input() assessmentId = '';
  @Input() isEvidence = false;

  private store = inject(Store);

  @ViewChild('hiddenInput') hiddenInput!: ElementRef<HTMLInputElement>;

  modalMetadata = {
    modalID: 'action-item-docs',
    modalWidth: 'sm:max-w-7xl',
    title: 'Attach Documents To Action Item',
  };

  readonly confirmModalData = {
    title: 'Remove Document',
    message: 'Are you sure you want to remove this document?',
  };

  documentDropped: string | number | null = null;

  files: FileWithPreview[] = [];
  counter = 0;

  ngOnInit(): void {
    if (!this.isEvidence) {
      this.store
        .select(selectActionItemById(this.actionItemId))
        .pipe(takeUntil(this.destroy$))
        .subscribe((actionItem) => {
          if (actionItem && actionItem.docs) {
            this.loadExistingDocs(actionItem.docs);
          }
        });
    } else {
      this.store
        .select(selectAssessmentById(this.assessmentId))
        .pipe(takeUntil(this.destroy$))
        .subscribe((assessment) => {
          if (assessment && assessment.evidence) {
            this.loadExistingDocs(
              assessment.evidence.find(
                (evidence) => evidence.actionItemId === this.actionItemId,
              )?.doc || [],
            );
          }
        });
    }
    this.modalMetadata.title = this.isEvidence
      ? 'Evidence Vault'
      : 'Attach Documents To Action Item';
  }

  loadExistingDocs(docs: IDoc[]): void {
    this.files = [];
    docs.forEach((doc) => {
      const fileWithPreview: FileWithPreview = {
        _id: doc._id,
        src: null,
        name: doc.filename,
        size: 0,
        url: doc.url,
        type: doc.contentType,
        lastModified: 0,
      };
      this.files.push(fileWithPreview);
    });
    this.updateGallery();
  }

  handleFileInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      for (const file of Array.from(input.files)) {
        this.addFile(file);
      }
    }
  }

  //TODO: Figure out a way to pass file properties to fileWithPreview
  addFile(file: File): void {
    const isImage = file.type.match('image.*');
    const objectURL = URL.createObjectURL(file);

    const fileWithPreview: FileWithPreview = Object.assign(file, {
      src: isImage ? objectURL : null,
      _file: file,
    });

    this.files.push(fileWithPreview);
    this.updateGallery();
  }

  formatFileSize(size: number): string {
    return size > 1024
      ? size > 1048576
        ? Math.round(size / 1048576) + 'mb'
        : Math.round(size / 1024) + 'kb'
      : size + 'b';
  }

  updateGallery(): void {
    const gallery = document.getElementById('gallery');
    const empty = document.getElementById('empty');

    if (gallery && empty && this.files.length > 0) {
      empty.classList.add('hidden');
    }
  }

  nativeClick(): void {
    this.hiddenInput.nativeElement.click();
  }

  dropHandler(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer?.files) {
      for (const file of Array.from(event.dataTransfer.files)) {
        this.addFile(file);
      }
      this.counter = 0;
      const overlay = document.getElementById('overlay');
      overlay?.classList.remove('draggedover');
    }
  }

  dragEnterHandler(event: DragEvent): void {
    event.preventDefault();
    if (this.hasFiles(event)) {
      this.counter++;
      const overlay = document.getElementById('overlay');
      overlay?.classList.add('draggedover');
    }
  }

  dragLeaveHandler(event: DragEvent): void {
    this.counter--;
    if (this.counter === 0) {
      const overlay = document.getElementById('overlay');
      overlay?.classList.remove('draggedover');
    }
  }

  dragOverHandler(event: DragEvent): void {
    if (this.hasFiles(event)) {
      event.preventDefault();
    }
  }

  hasFiles(event: DragEvent): boolean {
    const types = event.dataTransfer?.types;
    return types ? types.includes('Files') : false;
  }

  deleteFile(): void {
    if (!this.isEvidence) {
      if (typeof this.documentDropped === 'number') {
        this.files.splice(this.documentDropped, 1);
        this.updateGallery();
      }

      if (typeof this.documentDropped === 'string') {
        this.store.dispatch(
          ActionItemsAppActions.removeActionItemDoc({
            id: this.actionItemId,
            docId: this.documentDropped,
          }),
        );
        this.updateGallery();
      }
    } else {
      if (typeof this.documentDropped === 'number') {
        this.files.splice(this.documentDropped, 1);
        this.updateGallery();
      }

      if (typeof this.documentDropped === 'string') {
        this.store.dispatch(
          AssessmentsAppActions.removeAssessmentEvidence({
            assessmentId: this.assessmentId,
            actionItemId: this.actionItemId,
            docId: this.documentDropped,
          }),
        );
        this.updateGallery();
      }
    }
  }

  callConfirmationModal(index: number | string): void {
    this.documentDropped = index;
    this.store.dispatch(ModalActions.open({ id: 'confirm-removing-document' }));
  }

  submitFiles(): void {
    const newFiles = this.files.filter((file) => !file.url);

    if (this.isEvidence) {
      this.store.dispatch(
        AssessmentsAppActions.uploadAssessmentEvidence({
          assessmentId: this.assessmentId,
          actionItemId: this.actionItemId,
          files: newFiles,
        }),
      );
    } else {
      this.store.dispatch(
        ActionItemsAppActions.uploadActionItemDocs({
          id: this.actionItemId,
          files: newFiles,
        }),
      );
    }
  }

  cancelUpload(): void {
    this.store.dispatch(
      ModalActions.close({
        id: this.modalMetadata.modalID,
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
