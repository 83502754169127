import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { IOrganization, IOrganizationBase } from '../models/organization.model';
import { IAvatar } from '../models/user.model';
import { Pagination } from 'src/app/state/organizations/organizations.state';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private readonly http = inject(HttpClient);

  getOrganizations(
    page?: number,
    limit?: number,
  ): Observable<{ organizations: IOrganization[]; pagination: Pagination }> {
    return this.http.get<{
      organizations: IOrganization[];
      pagination: Pagination;
    }>(`${environment.apiUrl}/organizations?page=${page}&limit=${limit}`);
  }

  searchOrganizations(
    search: string,
    page: string,
    limit: string,
  ): Observable<{ organizations: IOrganization[]; pagination: Pagination }> {
    return this.http.get<{
      organizations: IOrganization[];
      pagination: Pagination;
    }>(
      `${environment.apiUrl}/organizations/search?search=${search}&page=${page}&limit=${limit}`,
    );
  }
  getOrganizationById(organizationId: string): Observable<{
    organization: IOrganization;
  }> {
    return this.http.get<{ organization: IOrganization }>(
      `${environment.apiUrl}/organizations/${organizationId}`,
    );
  }

  createOrganization(
    organization: IOrganizationBase,
  ): Observable<{ organization: IOrganization }> {
    return this.http.post<{ organization: IOrganization }>(
      `${environment.apiUrl}/organizations`,
      organization,
    );
  }

  updateOrganization(
    organization: IOrganization,
  ): Observable<{ organization: IOrganization }> {
    // const { avatars, ...updatedOrganization } = organization;
    return this.http.patch<{ organization: IOrganization }>(
      `${environment.apiUrl}/organizations/${organization._id}`,
      organization,
    );
  }

  removeOrganization(
    organizationId: string,
  ): Observable<{ organizationId: string; message: string }> {
    return this.http.delete<{ organizationId: string; message: string }>(
      `${environment.apiUrl}/organizations/${organizationId}`,
    );
  }

  updateOrganizationsAvatars(
    organizationId: string,
    avatar: IAvatar,
  ): Observable<{ organizationId: string; avatars: IAvatar[] }> {
    return this.http.put<{ organizationId: string; avatars: IAvatar[] }>(
      `${environment.apiUrl}/organizations/${organizationId}/avatars`,
      { avatar },
    );
  }

  removeOrganizationAvatar(
    organizationId: string,
    avatar: IAvatar,
  ): Observable<{ organizationId: string; avatar: IAvatar }> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { avatar },
    };
    return this.http.delete<{ organizationId: string; avatar: IAvatar }>(
      `${environment.apiUrl}/organizations/${organizationId}/avatars`,
      options,
    );
  }

  updateOrganizationStatus(obj: { organizationId: string; isActive: boolean }) {
    return this.http.patch<{ organization: IOrganization }>(
      `${environment.apiUrl}/organizations/${obj.organizationId}/status`,
      {
        isActive: obj.isActive,
      },
    );
  }

  updateOrganizationBrandingLink(obj: {
    organizationId: string;
    link: string;
    brandPageContent?: string;
  }): Observable<{ organization: IOrganization; message: string }> {
    return this.http.patch<{
      organization: IOrganization;
      message: string;
    }>(
      `${environment.apiUrl}/organizations/${obj.organizationId}/branding-link`,
      {
        link: obj.link,
        brandPageContent: obj.brandPageContent,
      },
    );
  }

  createUserOrganization(
    org: Pick<
      IOrganizationBase,
      'name' | 'addresses' | 'phoneNumbers' | 'avatars' | 'paymentIntentId'
    >,
  ): Observable<{ organization: IOrganization; message: string }> {
    return this.http.post<{ organization: IOrganization; message: string }>(
      `${environment.apiUrl}/organizations/branded`,
      {
        ...org,
      },
    );
  }

  checkBrandedOrganizationLink(
    orgLink: string,
  ): Observable<{ exists: boolean }> {
    return this.http.get<{ exists: boolean }>(
      `${environment.apiUrl}/organizations/${orgLink}/exists`,
    );
  }

  getBrandedOrganizationInfo(orgLink: string): Observable<{ org: {name: string, avatars: IAvatar[], brandPageContent: string} }> {
    return this.http.get<{ org: {name: string, avatars: IAvatar[], brandPageContent: string} }>(
      `${environment.apiUrl}/organizations/${orgLink}/info`,
    );
  }


  createSubBrandedOrganization(orgLink: string, adminDetails:any, orgDetails:any, paymentIntentId:string): Observable<{message: string}>{
    return this.http.post<{message: string}>(`${environment.apiUrl}/organizations/${orgLink}/signup`, {
      adminDetails,
      orgDetails,
      paymentIntentId
    })
  }
}
