// 1. Angular Core imports
import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';

// 2. Third-party libraries
import { Store } from '@ngrx/store';
import { TabViewModule } from 'primeng/tabview';

// 3. Application-wide shared models
import { IRole, UserRole } from '../core/models/user.model';
import { ITab } from '../core/models/tab.model';

// 4. State management
import { selectUserRole } from '../state/auth/auth.selectors';
import { selectHasSubOrganizations } from '../state/organizations/organizations.selectors';
import { combineLatest } from 'rxjs';
import { DashboardPageStore } from './dashboard-page.store';
import { AssessmentPageStore } from '../assessment-page/assessment-page.store';

// 5. Shared components
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { TabsComponent } from '../shared/tabs/tabs.component';
import { SharedAssessmentHeaderComponent } from '../shared/shared-assessment-header/shared-assessment-header.component';

// 6. Feature-specific components
import { DashboardPageHeaderComponent } from './dashboard-page-header/dashboard-page-header.component';
import { DashboardPageListComponent } from './dashboardp-page-list/dashboard-page-list.component';
import { DashbaordPageAdminComponent } from './dashbaord-page-admin/dashbaord-page-admin.component';
import { DashboardPageAssessmentComponent } from './dashboard-page-assessment/dashboard-page-assessment.component';
import { OrganizationsAppActions } from '../state/organizations/organizations.actions';

@Component({
  selector: 'cap-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    SidebarComponent,
    TabViewModule,
    TabsComponent,
    RouterOutlet,
    DashboardPageAssessmentComponent,
  ],
  templateUrl: './dashboard-page.component.html',
  styleUrl: './dashboard-page.component.scss',
})
export class DashboardPageComponent implements OnInit {
  // 1. Dependency Injection
  private router = inject(Router);
  private dashboardPageStore = inject(DashboardPageStore);
  private assessmentPageStore = inject(AssessmentPageStore);
  private store = inject(Store);

  // 2. Private properties
  private currentUserRole: IRole | undefined = undefined;

  // 3. Public properties
  showDashboardPageList: boolean = false;
  showOrgDash = false;
  isSysAdmin = false;

  // 4. Readonly properties and constants
  protected readonly UserRole = UserRole;
  readonly entity = this.assessmentPageStore.currentEntity$;
  readonly tabs: ITab[] = [
    {
      name: 'Assessments Dashboard',
      route: 'dashboard/assessments',
      current: false,
      icon: 'assessment',
    },
    {
      name: 'Organizations Dashboard',
      route: 'dashboard/organizations',
      current: false,
      icon: 'corporate_fare',
    },
  ];

  // 5. Lifecycle Hooks
  ngOnInit(): void {
    this.initializeUserRole();
    this.initializeEntity();
  }

  // 6. Private Methods
  private initializeUserRole(): void {
    combineLatest([
      this.store.select(selectUserRole),
      this.store.select(selectHasSubOrganizations)
    ]).subscribe(([role, hasSubOrgs]) => {
      this.currentUserRole = role;
      if (role?.name === UserRole.SysAdmin) {
        this.isSysAdmin = true;
        this.showOrgDash = true;
        this.router.navigate([this.tabs[0].route]);
      } else if (role?.name === UserRole.OrgAdmin && hasSubOrgs) {
        this.showOrgDash = true;
        this.router.navigate([this.tabs[0].route]);
      } else {
        this.showOrgDash = false;
      }
    });
  }

  private initializeEntity(): void {
    this.entity.subscribe((entity) => {
      if (entity) {
        this.dashboardPageStore.toggleAssessmentStats(false);
        this.showDashboardPageList = true;
      }
    });
  }

  // 7. Public Methods
  onTabChange(route: string): void {
    this.router.navigate([route]);
  }
}