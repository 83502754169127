<div class="my-3 space-y-3">
  <cap-shared-assessment-header
    [isBranded]="true"
  ></cap-shared-assessment-header>

  <form
    [formGroup]="brandDetailsForm"
    (ngSubmit)="onSubmit()"
    class="space-y-4"
  >
    <div
      class="w-full space-y-6 py-8 px-8 rounded-2xl bg-white dark:bg-slate-800 border-2 border-gray-200 dark:border-gray-700"
    >
      <!-- Loading Spinner -->
      <ng-container *ngIf="loading$ | async">
        <div
          class="absolute inset-0 bg-gray-100 dark:bg-gray-800 bg-opacity-50 flex items-center justify-center"
        >
          <cap-spinner [size]="16"></cap-spinner>
        </div>
      </ng-container>

      <!-- Company Website -->
      <div class="flex flex-col items-center">
        <label
          for="company-website"
          class="block text-lg font-medium text-gray-700 dark:text-gray-200"
        >
          Company Website
        </label>
        <div class="mt-2 flex rounded-md shadow-sm w-full max-w-3xl">
          <span
            class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 text-base"
          >
            {{ appUrl + "/" }}
          </span>
          <input
            type="text"
            name="link"
            id="company-website"
            formControlName="link"
            class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-base dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            placeholder="company"
          />
        </div>
        <!-- Display validation errors -->
        <cap-form-error-message
          [control]="brandDetailsForm.get('link')!"
          class="mt-2"
        ></cap-form-error-message>
      </div>
    </div>

    <!-- Brand Page Content -->
    <div class="flex flex-col items-center">
      <label
        for="brand-page-content"
        class="block text-lg font-medium text-gray-700 dark:text-gray-200"
      >
        Brand Page Content
      </label>
    </div>
    <div>
      <label for="comment" class="block text-sm/6 font-medium text-gray-900">Add your comment</label>
      <div class="mt-2">
        <textarea formControlName="brandPageContent" frows="4" name="comment" id="comment" class="block w-full rounded-md dark:bg-slate-900 dark:border-gray-700 dark:caret-white bg-white px-3 py-1.5 text-base dark:text-white text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-red-600 sm:text-sm/6"></textarea>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="mt-6 flex justify-center">
      <button
        type="submit"
        [disabled]="brandDetailsForm.invalid"
        class="px-8 py-3 text-white rounded-md focus:outline-none bg-primary-600 hover:bg-primary-500 disabled:bg-primary-300 text-base font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      >
        Save Changes
      </button>
    </div>
  </form>
</div>
