<cap-modal-container [modalID]="modalMetadata.modalID" [modalWidthClass]="modalMetadata.modalWidth" [title]="modalMetadata.title">
    <p class="text-xl font-bold text-gray-900 dark:text-gray-50" heading>
        {{ modalMetadata.title }}
      </p>
    <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">
        <div class="flex flex-col gap-y-4">
            <p class="text-sm text-gray-500">
                Please share your feedback with us so we can improve our platform.
            </p>
            <div>
                <label for="title" class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-50">Title</label>
                <div class="mt-2">
                  <input formControlName="title" type="text" name="title" id="title" class="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:bg-gray-800 dark:text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" placeholder="Title">
                </div>
            </div>
            <div>
                <label for="description" class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-50">Description</label>
                <div class="mt-2">
                  <textarea formControlName="description" rows="4" name="description" id="description" class="block w-full rounded-md border-0 py-1.5 dark:bg-gray-800 text-gray-900 dark:text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"></textarea>
                </div>
              </div>
        </div>
        <div class="flex justify-end gap-x-4 mt-4">
            <button type="button" class="bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-50 px-4 py-2 rounded-md" (click)="closeModal()">Cancel</button>
            <button type="submit" class="bg-primary-600 text-white px-4 py-2 rounded-md">Submit</button>
        </div>
    </form>
</cap-modal-container>
